<div id="step1" class="row padded-row padded-row-extra">
    <div class="col-sm-12 col-md-8">
        <form novalidate [formGroup]="formStep1">
            <div class="row">

                <div class="col-sm-12 col-md-6 col-lg-5">
                    <label class="input-label" for="currentAge">Select the coverage that best fits your needs:</label>
                    <select class="form-control" formControlName="coverageType">
                        <!-- (ngModelChange)="currentAgeChange($event)" -->
                        <option value="S"> Single </option>
                        <option value="F"> Family</option>
                    </select>
                    <app-error-message [errorMessage]="formErrors.coverageType"></app-error-message>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-5">
                    <label class="input-label" for="currentAge">Do you currently have a Health Savings Account (HSA)?</label>
                    <div class="form-group">

                        <select (ngModelChange)="togglePlanBalance($event)" class="form-control" formControlName="currentHSAParticipant">
                            <option value="Y"> Yes </option>
                            <option value="N"> No</option>
                        </select>
                        <app-error-message [errorMessage]="formErrors.currentHSAParticipant"></app-error-message>
                    </div>
                </div>

            </div>

            <div *ngIf="showPlanBalance" class="row input-row">
                <div class="col-sm-12 col-md-6 col-sm-offset-6 col-lg-5 col-lg-offset-5">
                    <div class="form-group">
                        <label class="input-label" for="currentAge">What is the HSA account balance?</label>
                        <div class="form-group">
                            <input (blur)="utilService.fmt($event)" (focus)="utilService.strip($event)" type="tel" formControlName="planBalance" class="form-control">
                            <app-error-message [errorMessage]="formErrors.planBalance"></app-error-message>
                        </div>
                        <app-error-message [errorMessage]="formErrors.currentAge"></app-error-message>
                    </div>
                </div>
            </div>

            <div class="row input-row">
                <div class="col-sm-12 col-md-6 col-lg-5">
                    <div class="form-group">
                        <!-- <label class="input-label" for="currentAge">What is your current age?</label> -->
                        <div class="input-label">What is your current age?</div>
                        <div class="form-group">
                            <select class="form-control" formControlName="currentAge" (ngModelChange)="currentAgeChange($event)">
                                <option *ngFor="let age of currentAgeOptions" [value]="age">
                                    {{age}}
                                </option>
                            </select>
                            <app-error-message [errorMessage]="formErrors.currentAge"></app-error-message>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-5">
                    <div class="form-group">
                        <div class="input-label">What tax brackets are you in?</div>
                        <div class="row">
                        <div class="col-sm-12 col-md-6 fedPadding">
                            <div class="form-group">
                                <label for="fedTax">Federal</label>
                                <select class="form-control" style="width: 90%;" formControlName="fedTax">
                                    <option *ngFor="let fed of fedTaxOptions" [value]="fed">
                                        {{fed}}%
                                    </option>
                                </select>
                                <app-error-message [errorMessage]="formErrors.fedTax"></app-error-message>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-6">
                            <div class="form-group">
                                <label for="stateTax">State*</label>
                                <select class="form-control" style="width: 90%;" formControlName="stateTax">
                                    <option *ngFor="let state of stateTaxOptions" [value]="state">
                                        {{state}}%
                                    </option>
                                </select>
                                <app-error-message [errorMessage]="formErrors.stateTax"></app-error-message>
                            </div>
                        </div>
                        </div>
                    </div>

                    <!-- </div> -->
                </div>
            </div>

            <div class="row input-row">
                <div class="col-sm-12 col-md-6 col-lg-5">
                    <div class="form-group">
                        <label class="input-label" for="currentAge">What is your retirement age?</label>
                        <select class="form-control" formControlName="retirementAge">
                            <option *ngFor="let age of retireAgeOptions" [value]="age">
                                {{age}}
                            </option>
                        </select>
                        <app-error-message [errorMessage]="formErrors.retirementAge"></app-error-message>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-5">
                    <div class="form-group">
                        <label class="input-label" for="retirementAge">What is the rate of return that best aligns with your investment strategy?</label>
                        <select class="form-control" formControlName="ror">
                            <option value="0"> 0% </option>
                            <option value="1"> 1% </option>
                            <option value="2"> 2% </option>
                            <option value="3"> 3% </option>
                            <option value="4"> 4% </option>
                            <option value="5"> 5% </option>
                            <option value="6"> 6% </option>
                            <option value="7"> 7% </option>
                            <option value="8"> 8% </option>
                            <option value="9"> 9% </option>
                            <option value="10"> 10% </option>
                        </select>
                        <app-error-message [errorMessage]="formErrors.ror"></app-error-message>
                    </div>
                </div>
            </div>


        </form>
    </div>
    <div class="col-md-4 col-sm-12">
        <div class="callout-container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="row-same-height">
                        <div class="col-same-height callout-title">
                            <div *ngIf="!catchupEligible">Did you know?</div>
                            <div *ngIf="catchupEligible" class="">You're eligible for a catch-up contribution</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="callout-text">
                        <p *ngIf="!catchupEligible">
                            At age {{constants.HSA_CATCHUP_AGE}}, you will be eligible for a catch-up contribution.
                            This is an additional {{constants.HSA_CATCHUP | currency:'USD':'symbol':'1.0-0'}} each
                            year over and above the annual contribution limit. This allows people nearing
                            retirement age to maximize their tax savings while increasing their savings
                            for health care in retirement.
                        </p>
                        <p *ngIf="catchupEligible">
                            Beginning at age {{constants.HSA_CATCHUP_AGE}}, you are eligible to contribute an additional
                            {{constants.HSA_CATCHUP | currency:'USD':'symbol':'1.0-0'}} each year over and above the
                            annual contribution limit. This allows people nearing retirement age to maximize
                            their tax savings while increasing their savings for health care in retirement.
                        </p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                    <div class="row-same-height">
                        <!-- <div class="col-same-height callout-icon">
                            <img src="assets/img/icon-tax-advantages.png" alt="icon-tax-advantages">
                        </div> -->
                        <div class="col-same-height callout-title">Triple Tax Advantages</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="callout-text">
                        <p>
                            <b>Pretax contributions:</b> All contributions to your HSA account are in pretax dollars. That
                            means you pay no taxes on the amount you save in the account.
                        </p>
                        <p>
                            <b>Tax-free gains:</b> As the account grows due to investment returns and dividend reinvestments
                            you will pay no taxes on those gains.
                        </p>
                        <p>
                            <b>Tax-free withdrawals:</b> An HSA has the additional advantage of being the only savings or
                            investment account for which you pay no taxes on withdrawals used to pay for qualified medical expenses.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>