export class FormData {

    // step 1
    coverageType = 'S';
    currentAge = 40;
    retirementAge = 65;

    // step 2
    currentHSAParticipant = 'N';
    planBalance = 0;
    fedTax = 24;
    stateTax = 5;
    ror = 5;

    // step 3
    hsaOOP = 0;
    hsaUsage = 'L';
    hsaCustom = 0;

    usageType = 'L';

    hsaUsageL = false;
    hsaUsageM = false;
    hsaUsageH = false;

    // step 4
    hsaContrib = 0;
    hsaSpend = 0;

    // summary
    hsaContribAlt = 0;
    hsaSpendAlt = 0;

}
