<div class="page-header mt-5 mb-4">

    <div class="row title-row padded-row">
        <div class="col-sm-6">
            <!-- <img src="assets/img/sscLogo.png" alt="logo" class="img-responsive"> -->
        </div>
        <div class="col-sm-6">
            <div class="text-right">
                <img width="20" src="./assets/img/calc.png" alt="pointer" class="hidden-xs">
                <span class="header-title">HSA Savings Calculator</span>
            </div>
        </div>
    </div>
    <div class="row progress-row padded-row" *ngIf="!firstPage">
        <div class="col-sm-10 col-md-10 no-pad-col">
            <div class="progress-title">{{stepTitle}}</div>
        </div>
        <!-- <div class="col-sm-12 col-sm-6 col-md-8 hidden-xs no-pad-col" *ngIf="showProgress">
            <div class="progress-bar-container">
                <progressbar [value]="progress"></progressbar>
                <div class="progress-text">
                    Step {{step}} of {{totalSteps}}
                </div>
            </div>
        </div> -->
        <!-- <div class="col-sm-2">
            <a href="#" style="color: white; font-size: 100px;margin-right: 20px" class="previous round">&#8249;</a>
            <a href="#" style="color: white; font-size: 100px;" class="next round">&#8250;</a>
        </div> -->
        <app-navigation></app-navigation>
    </div>

    <div class="row progress-row-xs padded-row visible-xs" *ngIf="!firstPage">
        <!-- <div class="col-sm-12 visible-xs">
            <div class="progress-bar-container-xs" *ngIf="showProgress">
                <progressbar [value]="progress"></progressbar>
                <div class="progress-text progress-text-xs">
                    step {{step}} of {{totalSteps}}
                </div>
            </div>
        </div> -->
    </div>
    <!-- <app-navigation></app-navigation> -->
</div>