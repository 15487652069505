<div id="summary" class="row padded-row padded-row-extra">
    <div class="col-sm-12">
        <div id="rptIntro1" class="rpt-intro1 mb-3">
            Based upon the information you entered, your estimated balance at retirement will be
            <span class="text-value">{{projRetBalance | currency:'USD':'symbol':'1.0-0'}}</span>
        </div>
        <p id="rptIntro2" class="page-intro">
            <!-- You could need {{retirementNeed | currency:'USD':'symbol':'1.0-0'}} for health care costs during
            retirement<sup>(1)</sup>. The information you provided in Steps 1 - 5 of the calculator indicate you are
            estimated to have {{projRetBalance | currency:'USD':'symbol':'1.0-0'}} at retirement for health
            care costs based on your current savings and spend level. The calculator also provides an
            option to look at your potential balance if you choose to increase annual HSA contributions
            or decrease the withdrawals you make each year for out-of-pocket expenses. You can adjust
            these numbers below the graph to see the impact of changes you may want to make. -->

            An individual could need more than {{retirementNeed | currency:'USD':'symbol':'1.0-0'}} to cover health care expenses
            in retirement. The data that you provided in the previous steps indicate that you could have an
            estimated {{projRetBalance | currency:'USD':'symbol':'1.0-0'}} in savings at retirement to cover health care costs.

            <br /><br />
            By changing the numbers below the graph, you can view the impact on savings by changing your annual
            contributions to an HSA plan or by increasing/decreasing the amount that you withdraw to pay for current
            medical out-of-pocket expenses.
        </p>
    </div>

    <div style="width: 100%;" class="summaryContainer">
        <div class="col-sm-12">
            <!-- <div id="chart-container">
                <chart [options]="options" (load)="saveChartInstance($event.context)"></chart>
            </div> -->
            <ng-container>
                <highcharts-chart [(update)]="updateFlag"  [Highcharts]="Highcharts" style="display: block;"  [options]="options"></highcharts-chart>
            </ng-container>
            <div class="legend-container summaryBox">
                <div class="row">
                    <div class="col-sm-12 col-md-8">
                        <div class="legend-box  row-same-height" (click)="toggleSeries('proj')">
                            <div class="legend-title col-same-height">
                                Estimated savings at retirement age:
                            </div>
                            <div id="projRetBalance" class="legend-value col-same-height">
                                {{projRetBalance | currency:'USD':'symbol':'1.0-0'}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="showAlternate">
                    <div class="col-sm-12 col-md-8">
                        <div class="legend-box  row-same-height" (click)="toggleSeries('alt')">
                            <div class="legend-title col-same-height">
                                Potential balance with changes to savings/spending:
                            </div>
                            <div id="altRetBalance" class="legend-value col-same-height">
                                {{altRetBalance | currency:'USD':'symbol':'1.0-0'}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-sm-12">
                <div class="summaryBox">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="row-same-height">

                                <div class="col-same-height callout-title small">Your Summary</div>
                            </div>
                        </div>
                    </div>
                    <div *ngFor="let item of summaryItems">
                        <app-summary-item [summaryItem]="item"></app-summary-item>
                    </div>
                </div>
            </div>

            <div class="col-md-8 col-sm-12">
                <div class="summaryBox">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="row-same-height">

                                <div class="col-same-height callout-title small">Your Assumptions</div>
                            </div>
                        </div>
                    </div>
                    <form novalidate [formGroup]="formSummary">

                        <div class="row input-row vbottom">

                            <div style="margin-bottom: -30px;" class="offset-md-3 col-md-9 vbottom">
                                <div class="input-label">Current tax rate?</div>
                            </div>
                            <div class="col-sm-12 col-md-3 vbottom">
                                <div class="form-group">
                                    <label class="input-label" for="retirementAge">Retirement age?</label>
                                    <select class="form-control inputMd" (ngModelChange)="calc()" formControlName="retirementAge">
                                        <option *ngFor="let age of retireAgeOptions" (change)="calc()" [value]="age">
                                            {{age}}
                                        </option>
                                    </select>
                                    <app-error-message [errorMessage]="formErrors.retirementAge"></app-error-message>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-3 vbottom">
                                <!-- <div class="input-label">Current tax rate?</div> -->

                                <!-- <div class="col-sm-6 no-padding"> -->
                                    <div class="form-group ">
                                        <label class="input-label" for="fedTax">Federal</label>
                                        <select class="form-control inputMd" (ngModelChange)="calc()" formControlName="fedTax">
                                            <option *ngFor="let fed of fedTaxOptions" [value]="fed">
                                                {{fed}}%
                                            </option>
                                        </select>
                                        <app-error-message [errorMessage]="formErrors.fedTax"></app-error-message>
                                    </div>
                                </div>

                                <div class="col-md-3 vbottom">
                                    <div class="form-group">
                                        <label class="input-label" for="stateTax">State*</label>
                                        <select class="form-control inputMd" (ngModelChange)="calc()" (change)="setStateTax(stateTaxOptions[$event.target.selectedIndex])" formControlName="stateTax">
                                            <option *ngFor="let state of stateTaxOptions" [value]="state">
                                                {{state}}%
                                            </option>
                                        </select>
                                        <app-error-message [errorMessage]="formErrors.stateTax"></app-error-message>
                                    </div>
                                </div>
                            <!-- </div> -->

                            <div class="col-sm-12 col-md-3 vbottom">
                                <div class="form-group">
                                    <label class="input-label" for="ror">Investment rate of return?</label>
                                    <select class="form-control inputMd" (ngModelChange)="calc()" (change)="setFedTax($event.target.value)" formControlName="ror">
                                        <option value="0"> 0% </option>
                                        <option value="1"> 1% </option>
                                        <option value="2"> 2% </option>
                                        <option value="3"> 3% </option>
                                        <option value="4"> 4% </option>
                                        <option value="5"> 5% </option>
                                        <option value="6"> 6% </option>
                                        <option value="7"> 7% </option>
                                        <option value="8"> 8% </option>
                                        <option value="9"> 9% </option>
                                        <option value="10"> 10% </option>
                                    </select>
                                    <app-error-message [errorMessage]="formErrors.ror"></app-error-message>
                                </div>
                            </div>
                        </div>


                        <div style="margin-top: 20px;;" class="row">
                            <div class="col-sm-12 col-lg-10">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="input-label input-label-red">
                                            <div *ngIf="!contribAtMax && !spendAtMin">
                                                Adjust your numbers to see how increasing savings or decreasing withdrawals
                                                for out-of-pocket expenses could impact your estimated savings at retirement age.
                                            </div>
                                            <div *ngIf="contribAtMax && !spendAtMin">
                                                You are already contributing at the maximum. Adjust your numbers to see how decreasing
                                                withdrawals for out-of-pocket expenses could impact your estimated savings at retirement age.
                                            </div>
                                            <div *ngIf="!contribAtMax && spendAtMin">
                                                You are already spending at the minimum. Adjust your numbers to see how increasing savings
                                                for out-of-pocket expenses could impact your estimated savings at retirement age.
                                            </div>
                                            <div *ngIf="contribAtMax && spendAtMin">
                                                Congratulations! You are contributing and saving at the maximum levels. Keep it up!
                                            </div>
                                        </div>
                                    </div>

                                    <div [hidden]="contribAtMax">

                                        <div class="col-sm-12">
                                            <div class="form-group">
                                                <input id="hsaContribAlt" formControlName="hsaContribAlt" type="text">
                                                <div class="row slider-labels">
                                                    <div class="col-sm-4">
                                                        <div class="text-left">{{hsaMinContrib | currency:'USD':'symbol':'1.0-0'}}</div>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <div class="text-center"></div>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <div class="text-right">{{hsaMaxContrib | currency:'USD':'symbol':'1.0-0'}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="input-label">
                                                Slide the bar to see the impact of increasing your annual contribution.
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div [hidden]="spendAtMin">

                                        <div class="col-sm-12">
                                            <div class="slider-spend-container">

                                                <div class="slider-spend">
                                                    <div class="form-group" class="slider-2">
                                                        <input id="hsaSpendAlt" formControlName="hsaSpendAlt" type="text">
                                                        <div class="row slider-labels">
                                                            <div class="col-sm-4">
                                                                <div class="text-left">{{hsaMinSpend | currency:'USD':'symbol':'1.0-0'}}</div>
                                                            </div>
                                                            <div class="col-sm-4">
                                                                <div class="text-center"></div>
                                                            </div>
                                                            <div class="col-sm-4">
                                                                <div class="text-right">{{hsaMaxSpend | currency:'USD':'symbol':'1.0-0'}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="icon-spender-saver">
                                            <div class="icon-text">spender</div>
                                            <img src="assets/img/icon-spender.png" alt="icon-spender">
                                        </div> -->
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="input-label">
                                                Slide the bar to see the impact on your long term savings if you spend less on your
                                                annual out-of-pocket medical expenses.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>



    <!-- <div class="col-md-8 col-sm-12">
        <div class="input-label input-label-red">
            <div *ngIf="!contribAtMax && !spendAtMin">
                Adjust your numbers to see how increasing savings or decreasing withdrawals
                for out-of-pocket expenses could impact your estimated savings at retirement age.
            </div>
            <div *ngIf="contribAtMax && !spendAtMin">
                You are already contributing at the maximum. Adjust your numbers to see how decreasing
                withdrawals for out-of-pocket expenses could impact your estimated savings at retirement age.
            </div>
            <div *ngIf="!contribAtMax && spendAtMin">
                You are already spending at the minimum. Adjust your numbers to see how increasing savings
                for out-of-pocket expenses could impact your estimated savings at retirement age.
            </div>
            <div *ngIf="contribAtMax && spendAtMin">
                Congratulations! You are contributing and saving at the maximum levels. Keep it up!
            </div>
        </div>
    </div> -->

    <!-- <div id="summaryFootnote" class="col-md-8 summary-footnote">
        (1) Sources: Employee Benefits Research Institute, Issue Brief, no. 481, May 16, 2019. A 65-year-old couple, both with median drug expenses needs $301,000 to have a 90% chance of having enough money to cover health care expenses (excluding long-term care) in retirement. Savings needed for
        Medigap Premiums, Medicare Part B Premiums, Medicare Part D Premiums and Out-of-Pocket Drug Expenses for Retirement at age 65 in 2019. A 65-year-old man needs $144,000 or a 65-year-old woman would need $163,000 to have a 90% chance of having enough money to cover health care expenses
        (excluding long-term care) in retirement.
    </div> -->
</div>