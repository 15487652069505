import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { ModalDirective } from 'ngx-bootstrap/modal';

import { PageService } from '../../pages.service';
import { PageData } from '../../pages.model';
import { ValidationService } from '../form-validations/validation.service';
import { FormDataService } from '../form-data/form-data.service';
import { AnalyticsService } from '../../../shared/analytics/analytics.service';
import { ApiService } from '../../../shared/services/api.service';  //../../../../app/shared/services/api.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
// import { Subject } from 'rxjs';
// import { takeUntil } from 'rxjs/operators';
// import { interpolation1 } from '@angular/common/';


import { ReportDataService } from '../../../shared/models/reportdata.service';
import { ReportData } from '../../../shared/models/reportdata.model';
//import { ValueConverter } from '@angular/compiler/src/render3/view/template';


@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
    pageData: PageData;
    reportData: ReportData;
    formErrors: any;
    errorArray: Array<string> = [];
    @ViewChild('errorModal', { static: true }) public modal: ModalDirective;
    @ViewChild('pdfModal', { static: true }) public modal2: ModalDirective;

    myURL: any;


    /**
     * Creates an instance of NavigationComponent.
     * @param {PageService} pageService
     * @param {Router} router
     * @param {ValidationService} validationService
     *
     * @memberOf NavigationComponent
     */
    constructor(private pageService: PageService,
        private router: Router,
        private reportDataService: ReportDataService,
        private apiService: ApiService,
        private validationService: ValidationService,
        private formDataService: FormDataService,
        private analyticsService: AnalyticsService) { }

    /**
     * Init actions when Angular is done creating the component.
     *
     * @memberOf NavigationComponent
     */
    ngOnInit() {
        this.pageData = this.pageService.pageData;
    }

    /**
     * Navigate to the previous page.
     *
     * @memberOf NavigationComponent
     */
    navPrev() {
        this.router.navigate(['/' + this.pageData.prevPage]);
    }

    /**
     * Navigate to the next page.
     * First we validate the current form to make sure we can move to next page.
     *
     * @memberOf NavigationComponent
     */
    navNext() {

        // validate form (first must mark all as dirty)
        const currentForm = this.formDataService.currentFormGroup;
        for (const field in currentForm.controls) {
            if (currentForm.controls.hasOwnProperty(field)) {
                const control = currentForm.get(field);
                control.markAsDirty();
            }
        };
        this.validationService.validateFormData(currentForm.value, currentForm);

        // navigate to the next page if form is valid
        // if invalid show error modal
        if (this.validationService.validForm) {
            this.analyticsService.sendAllInputs(currentForm.value);
            this.router.navigate(['/' + this.pageData.nextPage]);
        } else {
            this.errorArray = this.validationService.validationArray;
            this.modal.show();
        }
    }




    pdfModalOpen() {
        this.modal2.show();
    }
    pdfModalClose() {
        this.modal2.hide();
    }
    pdfPrint() {

        var jsonData = new Array();

        var mydate = new Date();
        var filename =
            'HSA-' + Math.floor(Math.random() * 1000000) + '-' + mydate.getTime()

        this.reportDataService.reportData.client_name = (<HTMLInputElement>document.getElementById("client_fname")).value + " " + (<HTMLInputElement>document.getElementById("client_lname")).value;
        this.reportDataService.reportData.advisor_name = (<HTMLInputElement>document.getElementById('advisor_name')).value;
        this.reportDataService.reportData.advisor_company = (<HTMLInputElement>document.getElementById("advisor_company")).value;
        this.reportDataService.reportData.advisor_phone = (<HTMLInputElement>document.getElementById("advisor_phone")).value;
        this.reportDataService.reportData.advisor_email = (<HTMLInputElement>document.getElementById("advisor_email")).value;

        jsonData.push({
            "rptIntro1": this.reportDataService.reportData.rptIntro1,
            "rptIntro2": this.reportDataService.reportData.rptIntro2,
            "summaryFootnote": this.reportDataService.reportData.summaryFootnote,
            "client_name": this.reportDataService.reportData.client_name,
            "advisor_name": this.reportDataService.reportData.advisor_name,
            "advisor_company": this.reportDataService.reportData.advisor_company,
            "advisor_phone": this.reportDataService.reportData.advisor_phone,
            "advisor_email": this.reportDataService.reportData.advisor_email,
            "projRetBalance": this.reportDataService.reportData.projRetBalance,
            "altRetBalance": this.reportDataService.reportData.altRetBalance,
            "hsaContrib": this.reportDataService.reportData.hsaContrib,
            "hsaOOP": this.reportDataService.reportData.hsaOOP,
            "hsaSpendAlt": this.reportDataService.reportData.hsaSpendAlt,
            "hsaContribAlt": this.reportDataService.reportData.hsaContribAlt,
            "taxSavings": this.reportDataService.reportData.taxSavings,
            "retirementAge": this.reportDataService.reportData.retirementAge,
            "fedTax": this.reportDataService.reportData.fedTax,
            "stateTax": this.reportDataService.reportData.stateTax,
            "ror": this.reportDataService.reportData.ror,
            "filename": filename
        });

        this.apiService.getPDF(jsonData).subscribe(
            response => {

                let url = location.origin;
                if (url.indexOf(".cloud") == -1) {
                    //let url = "http://fidelity-hsa.local:8073/pdf/" + filename + ".pdf";
                    let url = "http://fidhsa/report/pdf/" + filename + ".pdf";
                    //console.log( 'calling window.open with URL: ' + url)
                    window.open(url, '_blank');
                } else {

                    if (url.indexOf("fidelity-hsa-uat") != -1) {
                        window.open("https://fidelity-hsa-uat.ssnc.cloud/report/pdf/" + filename + ".pdf", '_blank');
                    } else  if (url.indexOf("fidelity-hsa-kc") != -1 ) {
                        window.open("https://fidelity-hsa-kc.ssnc.cloud/report/pdf/" + filename + ".pdf", '_blank');
                    } else  if (url.indexOf("fidelity-hsa-stl") != -1 ) {
                        window.open("https://fidelity-hsa-stl.ssnc.cloud/report/pdf/" + filename + ".pdf", '_blank');
                    } else  if (url.indexOf("fidelity-hsa") != -1 ) {
                        window.open("https://fidelity-hsa.ssnc.cloud/report/pdf/" + filename + ".pdf", '_blank');

                    } else  if (url.indexOf("fidadvisor.") != -1 ) {
                        window.open("https://fidadvisor.ssnc.cloud/hsa/report/pdf/" + filename + ".pdf", '_blank');
                    } else  if (url.indexOf("fidadvisor-uat") != -1 ) {
                        window.open("https://fidadvisor-uat.ssnc.cloud/hsa/report/pdf/" + filename + ".pdf", '_blank');

                    } else {
                        window.open("https://fidelity-hsa.ssnc.cloud/report/pdf/" + filename + ".pdf", '_blank');
                    }
                }
                this.pdfModalClose();
            }
        );

    }







    /**
     * Hide the visible modal.
     *
     * @memberOf NavigationComponent
     */
    closeModal() {
        this.modal.hide();
    }

    print() {
        window.print();
        // const head = document.getElementsByTagName('head')[0].innerHTML;
        // const header = document.getElementsByClassName('page-header')[0].innerHTML;
        // const results = document.getElementById('results').innerHTML;
        // const html = head + header + results;
        // const newWindow = window.open('', 'PrintWindow',
        //     'width=750,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes');
        // newWindow.document.writeln(html);
        // newWindow.document.close();
        // newWindow.focus();
        // newWindow.print();
    }




}
