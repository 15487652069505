import { Injectable } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { UntypedFormGroup } from '@angular/forms';

import { FormDataService } from '../form-data/form-data.service';

@Injectable()
export class ValidationService {

    // validation messages
    validationMessages: any = {};
    validationErrors: any = {};
    validationArray: Array<string> = [];
    validForm = true;

    // validation amounts
    minCurrentAge = 18;
    maxCurrentAge = 99;

    minRetAge = 45;
    maxRetAge = 100;

    planBalGreaterThan = 0;
    planBalLessThan = 1000001;

    fedTaxOptions: number[] = [10, 12, 22, 24, 32, 35, 37];
    stateTaxMin = 0;
    stateTaxMax = 15;

    minROR = 0;
    maxROR = 10;

    customOOPGreaterThan = 0;
    customOOPLessThan = 50001;

    hsaMaxContrib: number;    // varies - by IRS limit
    hsaMaxSpend: number;      // varies - limited to contrib amount
    hsaMinContrib = 0;
    hsaMinSpend = 0;

    /**
     * Creates an instance of ValidationService.
     * @param {FormDataService} formDataService
     * @param {CurrencyPipe} currencyPipe
     *
     * @memberOf ValidationService
     */
    constructor(private formDataService: FormDataService,
                private currencyPipe: CurrencyPipe) {
        this.initValidationMessages();
    }

    /**
     * Init validation error messages for all form inputs.
     *
     * @memberOf ValidationService
     */
    initValidationMessages() {
        this.validationMessages = {
            // step 1
            'coverageType': {
                'required': 'Coverage type is required.',
            },
            'currentAge': {
                'required': 'Current age is required.'
            },
            'retirementAge': {
                'required': 'Retirement age is required.'
            },
            // step 2
            'currentHSAParticipant': {
                'required': 'Please elect if you have a current HSA account.',
            },
            'planBalance': {
                'required': 'Plan balance is required.',
                'gt': 'Please enter a plan balance of at least '
                    + this.currencyPipe.transform(this.planBalGreaterThan + 1, 'USD', 'symbol', '1.0-0'),
                'lt': 'Please enter a plan balance of '
                    + this.currencyPipe.transform(this.planBalLessThan - 1, 'USD', 'symbol', '1.0-0') + ' or less',
            },
            'fedTax': {
                'required': 'Federal tax bracket is required.'
            },
            'stateTax': {
                'required': 'State tax bracket is required.'
            },
            'ror': {
                'required': 'Investment risk tolerance is required.'
            },
            // step 3
            'hsaUsage': {
                'required': 'HSA Usage is required.',
            },
            'hsaOOP': {
                'required': 'HSA OOP is required.',
            },
            'hsaCustom': {
                'required': 'Annual out-of-pocket amount is required.',
                'gt': 'Please enter an annual out-of-pocket amount of at least '
                    + this.currencyPipe.transform(this.customOOPGreaterThan + 1, 'USD', 'symbol', '1.0-0'),
                'lt': 'Please enter an annual out-of-pocket amount of '
                    + this.currencyPipe.transform(this.customOOPLessThan - 1, 'USD', 'symbol', '1.0-0') + ' or less',
            },
            // step 4
            'hsaContrib': {
                'required': 'HSA Contribution is required.',
            },
            'hsaSpend': {
                'required': 'HSA Spend is required.',
            }
        };

        // empty string default message
        for (const key in this.validationMessages) {
            if (this.validationMessages.hasOwnProperty(key)) {
                this.validationErrors[key] = '';
            }
        }
    }

    /**
     * Get validation error message for specific field.
     *
     * @param {string} field
     * @returns {string}
     *
     * @memberOf ValidationService
     */
    getValidationErrorMessage(field: string) {
        return this.validationMessages[field];
    }

    /**
     * Validates form.
     *
     * @param {*} data
     * @param {FormGroup} form
     * @returns
     *
     * @memberOf ValidationService
     */
    validateFormData(data: any, form: UntypedFormGroup) {
        this.validationArray = [];
        this.validForm = true;
        this.formDataService.currentFormGroup = form;
        for (const field in data) {
            if (data.hasOwnProperty(field)) {
                this.validationErrors[field] = '';
                const control = form.get(field);
                if (control && control.dirty && !control.valid) {
                    this.validForm = false;
                    const messages = this.getValidationErrorMessage(field);
                    for (const key in control.errors) {
                        if (control.errors.hasOwnProperty(key)) {
                            this.validationErrors[field] += messages[key];
                            this.validationArray.push(messages[key]);
                        }
                    }
                }
            }
        }
        return this.validationErrors;
    }

}
