<div id="step3" class="row padded-row padded-row-extra">

    <div class="col-sm-12">
        <div class="input-label">Let's estimate your annual out-of-pocket health care costs.</div>
        <p class="page-intro">
            The first step in planning your HSA is to know your healthcare expenses. Healthcare expenses depend on your family size, age, where
            you live and current health status. Below are some possible average costs that you can choose from. You can also enter a specific
            dollar value.
        </p>
    </div>
    <form novalidate [formGroup]="formStep2">
        <div class="col-sm-12">

            <accordion [closeOthers]="oneAtATime">
                <accordion-group (click)="setHSALowMedHigh('L')" [isOpen]="openLow">
                    <button class="btn btn-link" accordion-heading type="button">
                        <div class="usage-title">Low Usage</div>
                      
                        <input [(ngModel)]="hsaUsageL" id="hsaUsageL" style="margin-left: 7px;" type="checkbox" name="hsaUsageL" formControlName="hsaUsageL" (click)="setHSALowMedHigh('L')" />
                        <span style="margin-left: 20px; font-size: 18px;" class="usage-amount">{{oopCosts[OOPProfile.L] | currency:'USD':'symbol':'1.0-0'}}</span>
                        <span style="margin-left: 5px" class="usage-subtitle">Estimated Annual Cost</span>
                   
                    </button>
                    <div class="usage-body">
                        <div [collapse]="lowCollapsed">
                            <div class="usage-content" *ngFor="let item of oopContent[OOPProfile.L]">
                                <div class="row-same-heightXX">
                                    <div class="col-same-height usage-icon" *ngIf="item.image">
                                        <img src="assets/img/{{item.image}}">
                                    </div>
                                    <div class="col-same-height usage-text" [innerHTML]="item.text"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </accordion-group>
                <accordion-group (click)="setHSALowMedHigh('M')" [isOpen]="openMod">
                    <button class="btn btn-link " accordion-heading type="button">
                        <span class="usage-title">Moderate Usage</span>
                       
                        <input [(ngModel)]="hsaUsageM" id="hsaUsageM" style="margin-left: 7px;" type="checkbox" name="hsaUsageM" formControlName="hsaUsageM" (click)="setHSALowMedHigh('M')">
                        <span style="margin-left: 20px; font-size: 18px;" class="usage-amount">{{oopCosts[OOPProfile.M] | currency:'USD':'symbol':'1.0-0'}}</span>
                        <span style="margin-left: 5px" class="usage-subtitle">Estimated Annual Cost</span>
                    
                    </button>
                    <div class="usage-body">
                        <div [collapse]="lowCollapsed">
                            <div class="usage-content" *ngFor="let item of oopContent[OOPProfile.M]">
                                <div class="row-same-heightXX">
                                    <div class="col-same-height usage-icon" *ngIf="item.image">
                                        <img src="assets/img/{{item.image}}">
                                    </div>
                                    <div class="col-same-height usage-text" [innerHTML]="item.text"></div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </accordion-group>
                <accordion-group (click)="setHSALowMedHigh('H')" [isOpen]="openHigh">
                    <button class="btn btn-link" accordion-heading type="button">
                        <span class="usage-title">High Usage </span>
                        
                        <input [(ngModel)]="hsaUsageH" id="hsaUsageH" style="margin-left: 7px;" type="checkbox" name="hsaUsageH" formControlName="hsaUsageH"  (click)="setHSALowMedHigh('H')">
                        <span style="margin-left: 20px; font-size: 18px;" class="usage-amount">{{oopCosts[OOPProfile.H] | currency:'USD':'symbol':'1.0-0'}}</span>
                        <span style="margin-left: 5px" class="usage-subtitle">Estimated Annual Cost</span>
                   
                    </button>
                    <div class="usage-body">
                        <div [collapse]="lowCollapsed">
                            <div class="usage-content" *ngFor="let item of oopContent[OOPProfile.H]">
                                <div class="row-same-heightXXXX">
                                    <div class="col-same-height usage-icon" *ngIf="item.image">
                                        <img src="assets/img/{{item.image}}">
                                    </div>
                                    <div class="col-same-height usage-text" [innerHTML]="item.text"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </accordion-group>
            </accordion>
        </div>

        <div class="col-sm-12">
            <div class="divider"><span class="oopOr">OR</span></div>
        </div>

        <div class="col-sm-12">
            <div class="input-label input-row">Enter here the amount if you know what your annual total out-of-pocket costs are:</div>
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-5">
                    <div class="form-group">
                        <input type="tel" (blur)="utilService.fmt($event)" (focus)="utilService.strip($event)" 
                        formControlName="hsaCustom" class="form-control" (click)="setHSACustom('C')" (ngModelChange)="setHSAOOP($event)">
                        <app-error-message [errorMessage]="formErrors.hsaCustom"></app-error-message>
                    </div>
                </div>
            </div>
        </div>

        <input type="hidden" formControlName="hsaUsage">
        <input type="hidden" formControlName="hsaOOP">

    </form>
</div>