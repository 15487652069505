import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ApiService {


    jsonPDFData: any;

    constructor(private httpClient: HttpClient,
        private router: Router
    ) { }



    getPDF(calcdata: any): Observable<any> {
console.log("getPDF");
        if (!calcdata) {
            return null;
        }

        // PHP POST CALL
        var myheaders = new HttpHeaders();
        myheaders.append('Content-Type', 'text/plain');
        myheaders.append('Content-Type', 'application/json');
        myheaders.append('Access-Control-Allow-Origin', '*');


        let thisURL = ""; // "http://fidelity-hsa.local:8073/report/hsareport.php";
        let url = location.origin;
        //console.log("url!: " + url);
        if (url.indexOf("cloud") == -1) {

            // thisURL = "http://fidelity-hsa.local:8073/report/hsareport.php";
            thisURL = "http://fidhsa/report/hsareport.php";
            console.log("thisURL: " + thisURL);
        } else {
            thisURL = location.origin;
            console.log("thisURL: " + thisURL);
            if (url.indexOf("fidelity-hsa-uat") != -1) {
                thisURL = "https://fidelity-hsa-uat.ssnc.cloud/report/hsareport.php";
            } else  if (url.indexOf("fidelity-hsa-kc") != -1 ) {
                thisURL = "https://fidelity-hsa-kc.ssnc.cloud/report/hsareport.php";
            } else  if (url.indexOf("fidelity-hsa-stl") != -1 ) {
                thisURL = "https://fidelity-hsa-stl.ssnc.cloud/report/hsareport.php";
            } else  if (url.indexOf("fidelity-hsa") != -1 ) {
                thisURL = "https://fidelity-hsa.ssnc.cloud/report/hsareport.php";
            } else  if (url.indexOf("fidadvisor.") != -1 ) {
                thisURL = "https://fidadvisor.ssnc.cloud/hsa/report/hsareport.php";
            } else  if (url.indexOf("fidadvisor-uat") != -1 ) {
                thisURL = "https://fidadvisor-uat.ssnc.cloud/hsa/report/hsareport.php";
            } else {
                thisURL = "https://fidelity-hsa.ssnc.cloud/report/hsareport.php";
            }

        }


        return this.httpClient.post<any>(thisURL, calcdata, { headers: myheaders });

    }

}
