<div id="step4" class="row padded-row padded-row-extra">
    <div class="row">

        <div class="col-md-8 col-sm-12">
            <form novalidate [formGroup]="formStep3">

                <div class="row">
                    <div class="col-sm-12 col-lg-10">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="input-label">How much will you annually contribute to your HSA?</div>
                            </div>
                            <div class="col-sm-12">
                                <div class="form-group slider-2">
                                    <input id="hsaContrib" formControlName="hsaContrib" type="text">
                                    <div class="row slider-labels">
                                        <div class="col-sm-4">
                                            <div class="text-left">{{validationService.hsaMinContrib | currency:'USD':'symbol':'1.0-0'}}</div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="text-center"></div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="text-right">{{validationService.hsaMaxContrib | currency:'USD':'symbol':'1.0-0'}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row input-row-large">
                            <div class="col-sm-12">
                                <div class="input-label">
                                    How much of your out-of-pocket healthcare cost
                                    of <span class="text-value">{{formData.hsaOOP | currency:'USD':'symbol':'1.0-0'}}</span> will
                                    you want to pay from your HSA account?

                                    <!-- You estimated your annual out-of-pocket health care costs to be
                                    <span class="text-value">{{formData.hsaOOP | currency:'USD':'symbol':'1.0-0'}}</span>.
                                    What amount of this will you pay with your HSA? -->
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="slider-spend-container">
                                    <!-- <div class="  icon-spender-saver">
                                    <div class="icon-text">saver</div>
                                    <img src="assets/img/icon-saver.png" alt="icon-saver">
                                </div> -->
                                    <div class="slider-spend">
                                        <div class="form-group" class="slider-2">
                                            <input id="hsaSpend" formControlName="hsaSpend" type="text">
                                            <div class="row slider-labels">
                                                <div class="col-sm-4">
                                                    <div class="text-left">{{validationService.hsaMinSpend | currency:'USD':'symbol':'1.0-0'}}</div>
                                                </div>
                                                <div class="col-sm-4">
                                                    <div class="text-center"></div>
                                                </div>
                                                <div class="col-sm-4">
                                                    <div class="text-right">{{validationService.hsaMaxSpend | currency:'USD':'symbol':'1.0-0'}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="icon-spender-saver">
                                    <div class="icon-text">spender</div>
                                    <img src="assets/img/icon-spender.png" alt="icon-spender">
                                </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-md-4 col-sm-12">
            <div class="callout-container">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="row-same-height">
                            <!-- <div class="col-same-height callout-icon">
                            <img src="assets/img/icon-tip.png" alt="icon-tip">
                        </div> -->
                            <div class="col-same-height callout-title">Tip</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="callout-text">
                            <p>
                                Money contributed to an HSA is exempt from federal and state income taxes
                                (in most states), and even earned interest. You own your HSA and it moves
                                with you if you change jobs, health plans or begin retirement. There are
                                no “use-it-or-lose- it” rules- the balance rolls over from year to year.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>