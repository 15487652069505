<div class="row">
    <div class="col-sm-12">
        <div class="landing-container">
            <!-- <img src="assets/img/landing-image-{{imageNum}}.jpg" alt="landing_image" class="img-responsive"> -->
            <!-- <img src="assets/img/header.png" alt="landing_image" class="img-responsive"> -->
            <!-- <div class="overlay">
                <div class="overlay-container">
                    <div class="overlay-text">
                        Find out if you're on track for health care costs in retirement
                    </div>
                </div>
            </div> -->

            <div class="col-sm-12">
                <div class="text-center">
                    <img width="50" src="./assets/img/calc.png" alt="pointer" class="">
                    <span class="header-MainTitle">HSA Savings Calculator</span>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="row landing-intro1">
    <div class="col-sm-12">
        <div class="get-started-container">
            <a class="btn btn-blue btn-getstarted" [routerLink]="['/step1']">
                Get started
                <!-- <img class="get-started-arrow" src="assets/img/icon-arrow-next.png" alt="icon-arrow"> -->
                <!-- <img class="btn-right-icon" src="assets/img/icon-calculator.png" alt="icon-calculator"> -->
            </a>
        </div>
    </div>
<!-- </div> -->


<!-- <div class="padded-row">
    <div class="row landing-intro1"> -->
        <!-- <div class="col-sm-2 hidden-xs">
            <img src="assets/img/icon-nest-egg.png" alt="nest-egg" class="img-responsive hidden-xs nest-egg-image">
        </div> -->
        <div class="offset-sm-1 col-sm-10">
            <div class="">
                <div class="landing-header">
                    <!-- Be ready to pay for health care costs now and in the future -->
                    How much do you need to save to help you meet health care costs in retirement?
                </div>
                <div class="row landing-text">
                    The Health Savings Account (HSA) calculator can help you estimate how much to set aside for health care expenses – now and in the future. In addition to helping you plan for your current year’s contribution, the calculator can show you how saving just a bit more or spending just
                    a bit less each year could impact your balance over the long term. Find out if you’re an HSA Saver or Spender and tips for making the most of your HSA.
                </div>
            </div>
        </div>
    <!-- </div> -->
</div>