import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { PageService } from '../pages.service';
import { PageData } from '../pages.model';

import { FormDataService } from '../shared/form-data/form-data.service';
import { FormData } from '../shared/form-data/form-data.model';
import { ValidationService } from '../shared/form-validations/validation.service';
import { CustomValidators } from 'ng2-validation';
import { CalculationsService } from '../shared/calculations/calculations.service';

import { OOP_CONTENT } from '../shared/oop/oop-content';
import { OOP_COSTS } from '../shared/oop/oop-costs';
import { OOPProfile } from '../shared/oop/oop-profile.type';
import { UtilService } from '../../shared/services/util.service';
import { AccordionComponent } from 'ngx-bootstrap/accordion';
import { CurrencyPipe } from '@angular/common';

import * as $ from 'jquery';
// import { ValueConverter } from '@angular/compiler/src/render3/view/template';

@Component({
    selector: 'app-step2',
    templateUrl: './step2.component.html',
    styleUrls: ['./step2.component.css']
})
export class Step2Component implements OnInit, OnDestroy {

    pageData: PageData;

    formStep2: FormGroup;
    formData: FormData;
    formDefaults: FormData;
    formErrors: any;
    oneAtATime: boolean = true;

    activeUsage: string;
    lowCollapsed = false;
    medCollapsed = false;
    highCollapsed = false;
    hsaUsage: string;
    usageType: string;
    openLow: boolean;
    openMod: boolean;
    openHigh: boolean;
    customOOPValidations: Array<any> = [];

    OOPProfile: OOPProfile;

    formSubscription: any;
    currencyMask: any;
    percentMask: any;
    numberMask: any;

    hsaUsageL: boolean;
    hsaUsageM: boolean;
    hsaUsageH: boolean;

    selected1: boolean;
    selected2: boolean;
    selected3: boolean;



    /**
     * Creates an instance of step2Component.
     * @param {PageService} pageService
     * @param {FormBuilder} formBuilder
     * @param {FormDataService} formDataService
     * @param {ValidationService} validationService
     * @param {CalculationsService} calculationService
     *
     * @memberOf step2Component
     */
    constructor(private pageService: PageService,
        private formBuilder: FormBuilder,
        private formDataService: FormDataService,
        private validationService: ValidationService,
        public  utilService: UtilService,
        private currencyPipe: CurrencyPipe,
        private calculationService: CalculationsService) { }

    /**
     * Init actions when Angular is done creating the component.
     *
     * @memberOf step2Component
     */
    ngOnInit() {

        this.hsaUsageL = false;
        this.hsaUsageM = false;
        this.hsaUsageH = true;

        this.selected1 = false;
        this.selected2 = true;
        this.selected3 = false;

        this.initPageData();
        this.initFormData();
        
        this.initOOPContent();

        this.initUsage();
        this.initMasks();

        // console.log("ngOnInit");

        // $("#hsaUsageL").prop( "checked", true );
        // $("#hsaUsageM").prop( "checked", false );
        // $("#hsaUsageH").prop( "checked", false );

        // this.setHSALowMedHigh("L");
        this.setHSALowMedHigh(this.formDataService.getValue('usageType'));


    }

    initMasks() {

        // this.currencyMask = this.utilService.getCurrencyMask();
        // this.percentMask = this.utilService.getPercentageMask();
        // this.numberMask = this.utilService.getNumberMask();
    }

    /**
     * Destroy actions when Angular is done with component.
     *
     * @memberOf step2Component
     */
    ngOnDestroy() {
        this.formSubscription.unsubscribe();
    }

    /**
     * Inits page specific data and sends this data to the page service.
     *
     * @memberOf step2Component
     */
    initPageData() {
        this.pageData = {
            currentPage: 'step2',
            nextPage: 'step3',
            prevPage: 'step1',
            stepNum: 3,
            progress: 75,
            firstPage: false,
            lastPage: false,
            showProgress: true,
            stepTitle: 'Annual health care costs',
        };
        this.pageService.initPage(this.pageData);
    }

    /**
     * Init data needed for form.
     *
     * @memberOf step2Component
     */
    initFormData() {
        this.formDefaults = this.formDataService.getDefaults();
        this.formData = this.formDataService.getFormData();
        this.formErrors = this.validationService.validationErrors;

        this.buildForm();
    }

    /**
     * Use FormBuilder to create form.
     * Subscribe to form value changes and validate.
     * Save data to form data service if valid.
     * Calculate values as needed.
     *
     * @memberOf step2Component
     */
    buildForm() {
        // validations for custom OOP field
        this.customOOPValidations = [
            Validators.required,
            CustomValidators.gt(this.validationService.customOOPGreaterThan),
            CustomValidators.lt(this.validationService.customOOPLessThan)
        ];

        // build step 3 form
        this.formStep2 = this.formBuilder.group({
            usageType: [this.formData.usageType, null],
            hsaUsage: [this.formData.hsaUsage, Validators.required],
            hsaOOP: [this.formData.hsaOOP, Validators.required],
            hsaCustom: [this.formData.hsaCustom, (this.formData.hsaUsage === 'C' ? this.customOOPValidations : null)],
            hsaUsageL: [this.formData.hsaUsageL, null],
            hsaUsageM: [this.formData.hsaUsageM, null],
            hsaUsageH: [this.formData.hsaUsageH, null],
        });

        // set current form data in formDataService
        this.formDataService.currentFormGroup = this.formStep2;

        // subscribe to step 3 form changes
        this.formSubscription = this.formStep2.valueChanges.subscribe(data => {

            this.formErrors = this.validationService.validateFormData(data, this.formStep2);
            if (this.formStep2.valid) {
                this.formDataService.setFormData(data);
            }
        });

        this.openLow = (this.formData.hsaUsage === "L");
        this.openMod = (this.formData.hsaUsage === "M");
        this.openHigh = (this.formData.hsaUsage === "H");
    }

  
    /**
     * Get OOP_COSTS constant in HTML.
     *
     * @readonly
     *
     * @memberOf step2Component
     */
    get oopCosts() {
        return OOP_COSTS;
    }

    /**
     * Get OOP_CONTENT constant in HTML.
     *
     * @readonly
     *
     * @memberOf step2Component
     */
    get oopContent() {
        return OOP_CONTENT;
    }

    /**
     * Set OOPProfile in calc service.
     *
     * @memberOf step2Component
     */
    initOOPContent() {
        this.calculationService.setOOPProfile(this.formData.currentAge, this.formData.coverageType);
        this.OOPProfile = this.calculationService.OOPProfile;
    }

    /**
     * Init HSA usage.
     * Get OOP cost from calc service for this user.
     * Set L/M/H/C usage in form.
     * Set OOP cost in form.
     *
     * @memberOf step2Component
     */
    initUsage() {

        const usage = this.formData.hsaUsage;
        if (usage === 'C') {
            this.setHSACustom(usage);
            this.hsaUsage = 'C'; 
        } else {
            this.hsaUsage = usage;
            this.setHSALowMedHigh(usage);
        }
        
        // console.log("initUsage");
    }

    /**
     * Set HSA OOP L/M/H usage and values.
     *
     * @param {string} usage
     *
     * @memberOf step2Component
     */
    setHSALowMedHigh(usage: string) {

        this.usageType = usage;
        this.formStep2.patchValue({ hsaCustom: 0 });
        this.setHSAUsage(usage);
        this.setHSAOOP(OOP_COSTS[this.OOPProfile[usage]]);
        this.updateCustomControl();
        this.calculationService.calcDefaultHSAContribSpend('forcespend');
        this.formDataService.setValue('usageType', usage);
    }

    /**
     * Set HSA OOP custom usage and value.
     *
     * @param {string} usage
     *
     * @memberOf step2Component
     */
    setHSACustom(usage: string) {

        this.setHSAUsage(usage);
        this.setHSAOOP(this.formStep2.controls['hsaCustom'].value);
        this.updateCustomControl();
    }

    /**
     * Sets active usage for L/M/H/C and sets form value.
     *
     * @param {string} usage
     *
     * @memberOf step2Component
     */
    setHSAUsage(usage: string) {
        // console.log("initUsage");console.log("setHSAUsage: " + usage);
        this.hsaUsage = usage;
        if (usage === 'C') {
            $("#hsaUsageL").prop( "checked", false );
            $("#hsaUsageM").prop( "checked", false );
            $("#hsaUsageH").prop( "checked", false );
        } 

        this.hsaUsageL = false;
        this.hsaUsageM = false;
        this.hsaUsageH = false;
        
        $("#hsaUsageL").prop( "checked", false );
        $("#hsaUsageM").prop( "checked", false );
        $("#hsaUsageH").prop( "checked", false );

        if (this.hsaUsage === "L") { 
            this.hsaUsageL = true;$("#hsaUsageL").prop( "checked", true ); 
        }
        if (this.hsaUsage === "M") { 
            this.hsaUsageM = true;$("#hsaUsageM").prop( "checked", true ); 
        }
        if (this.hsaUsage === "H") { 
            this.hsaUsageH = true;$("#hsaUsageH").prop( "checked", true ); 
        }

        this.activeUsage = usage;
        this.formStep2.patchValue({ hsaUsage: usage });
    }

    /**
     * Sets OOP value in form.
     *
     * @param {number} oop
     *
     * @memberOf step2Component
     */
    setHSAOOP(oop: number) {
        this.formStep2.patchValue({ hsaOOP: oop });
    }

    /**
     * Toggle validations on custom control.
     *
     * @memberOf step2Component
     */
    updateCustomControl() {

        // this.formStep2.setValue({ hsaCustom: this.utilService.stripfmt(this.formStep2.get('hsaCustom').value) });

        const control = this.formStep2.get('hsaCustom');

        // control.setValue(parseInt(this.utilService.stripfmt(control.value)));
        if (this.formStep2.get('hsaUsage').value === 'C') {
            control.setValidators(this.customOOPValidations);
        } else {
            control.setValidators(null);
        }
        control.updateValueAndValidity();
    }

}
