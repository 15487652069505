/**
 * CONSTANTS for use in calcs and display.
 * 
 * 2024

Annual Contribution Limit (*Under High Deductible Health Plan)
$4150 (Individuals)
$8300 (Families)

Maximum Deductible*
$1600 (Individuals)
$3200 (Families)

Maximum Out-of-Pocket Expenses*
$8050 (Individuals)
$16100 (Families)



 */
export const CONSTANTS = {
    copyYear: 2024,                     // current COPYRIGHT year of tool (update annually)
    currentYear: 2024,                  // current year of tool ***FOR CALCULATIONS*** (update annually)
    HSA_SINGLE: 4150,                   // HSA IRS limit single (update annually)
    HSA_FAMILY: 8300,                   // HSA IRS limit family (update annually)
    HSA_CATCHUP: 1000,                  // HSA IRS catchup amount (update annually)
    HSA_CATCHUP_AGE: 55,                // HSA IRS catchup age
    RETIREMENT_NEED: 315000,            // retirement need for family
    RETIREMENT_NEED_SINGLE_F: 165000,   // retirement need for single (female)
    RETIREMENT_NEED_SINGLE_M: 165000,   // retirement need for single (male)
    SPEND_SAVE_PCT: 50,                 // percent to determine spender vs saver
    ALTERNATE_CONTRIB_PCT: 10,          // percent recommendation for alternate HSA contrib amounts
    ALTERNATE_SPEND_PCT: 10,            // percent recommendation for alternate HSA spend amounts
    ALTERNATE_DOLLAR: 100,              // dollar amount recommendation for non-contributors
    SLIDER_STEP: 1,                    // step for HSA slider
};
