import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { PageService } from '../pages.service';
import { PageData } from '../pages.model';
import { UtilService } from '../../shared/services/util.service';
import { FormDataService } from '../shared/form-data/form-data.service';
import { FormData } from '../shared/form-data/form-data.model';
import { CalculationsService } from '../shared/calculations/calculations.service';
import { CONSTANTS } from '../shared/calculations/calc-constants';
import { ValidationService } from '../shared/form-validations/validation.service';

@Component({
    selector: 'app-step1',
    templateUrl: './step1.component.html',
    styleUrls: ['./step1.component.css']
})
export class Step1Component implements OnInit, OnDestroy {

    pageData: PageData;

    formStep1: FormGroup;
    formData: FormData;
    formErrors: any;

    currentAgeOptions: Array<number> = [];
    retireAgeOptions: Array<number> = [];

    fedTaxOptions: Array<number> = [];
    stateTaxOptions: Array<number> = [];

    showPlanBalance = false;
    planBalanceValidations: Array<any> = [];

    catchupEligible: boolean;

    calloutTitle: string;

    private formSubscription: any;

    /**
     * Creates an instance of Step1Component.
     * @param {PageService} pageService
     * @param {FormBuilder} formBuilder
     * @param {FormDataService} formDataService
     * @param {CalculationsService} calculationService
     * @param {ValidationService} validationService
     *
     * @memberOf Step1Component
     */
    constructor(private pageService: PageService,
        private formBuilder: FormBuilder,
        private formDataService: FormDataService,
        private calculationService: CalculationsService,
        private utilService: UtilService,
        private validationService: ValidationService) { }

    /**
     * Init actions when Angular is done creating the component.
     *
     * @memberOf Step1Component
     */
    ngOnInit() {
        this.initPageData();
        this.initFormData();
        this.buildCurrentAgeOptions();
        this.buildRetirementAgeOptions(this.validationService.minRetAge);

        this.togglePlanBalance(this.formData.currentHSAParticipant);
        this.buildFedTaxOptions();
        this.buildStateTaxOptions();

        this.setCatchupEligible(this.formData.currentAge);
    }

    /**
     * Destroy actions when Angular is done with component.
     *
     * @memberOf Step1Component
     */
    ngOnDestroy() {
        this.formSubscription.unsubscribe();
    }

    /**
     * Inits page specific data and sends this data to the page service.
     *
     * @memberOf Step1Component
     */
    initPageData() {
        this.pageData = {
            currentPage: 'step1',
            nextPage: 'step2',
            prevPage: '',
            stepNum: 1,
            progress: 25,
            firstPage: false,
            lastPage: false,
            showProgress: true,
            stepTitle: 'About you',
        };
        this.pageService.initPage(this.pageData);
    }

    /**
     * Init data needed for form.
     *
     * @memberOf Step1Component
     */
    initFormData() {
        this.formData = this.formDataService.getFormData();
        this.formErrors = this.validationService.validationErrors;
        this.buildForm();
    }

    /**
     * Use FormBuilder to create form.
     * Subscribe to form value changes and validate.
     * Save data to form data service if valid.
     * Calculate values as needed.
     *
     * @memberOf Step1Component
     */
    buildForm() {
        // build form
        this.formStep1 = this.formBuilder.group({
            coverageType: [this.formData.coverageType, Validators.required],
            currentAge: [this.formData.currentAge, Validators.required],
            retirementAge: [this.formData.retirementAge, Validators.required],

            currentHSAParticipant: [this.formData.currentHSAParticipant, Validators.required],
            planBalance: [this.formData.planBalance, this.planBalanceValidations],
            fedTax: [this.formData.fedTax, Validators.required],
            stateTax: [this.formData.stateTax, Validators.required],
            ror: [this.formData.ror, Validators.required]

        });

        // set current form data in formDataService
        this.formDataService.currentFormGroup = this.formStep1;

        // subscribe to form value changes
        this.formSubscription = this.formStep1.valueChanges.subscribe(data => {
            this.formErrors = this.validationService.validateFormData(data, this.formStep1);
            if (this.formStep1.valid) {
                this.formDataService.setFormData(data);
            }
            this.calculationService.calcHSAMax(data.currentAge, data.coverageType);
            this.calculationService.calcDefaultHSAContribSpend('force');
            this.calculationService.calcRetirementNeed(data.coverageType);
            this.calculationService.calcYearsUntilRetirement(data.currentAge, data.retirementAge);
        });
    }

    /**
     * Build the options for the current age select.
     * Confined by the minAge/maxAge class properties.
     *
     * @memberOf Step1Component
     */
    buildCurrentAgeOptions() {
        const min = this.validationService.minCurrentAge;
        const max = this.validationService.maxCurrentAge;
        for (let i = min; i <= max; i++) {
            this.currentAgeOptions.push(i);
        }
    }

    /**
     * Build the options for the retirement age select.
     * Confined by a age param and maxRetAge class property.
     *
     * @param {number} age
     *
     * @memberOf Step1Component
     */
    buildRetirementAgeOptions(age: number) {
        // build retirement age options
        const min: number = age;
        const max: number = this.validationService.maxRetAge;
        this.retireAgeOptions = [];
        for (let i = min; i <= max; i++) {
            this.retireAgeOptions.push(i);
        }

        // update retirement age if selected current age is greater
        const minAge = this.formStep1.controls['retirementAge'].value;
        if (age > minAge) {
            this.formStep1.patchValue({ retirementAge: age });
        }
    }


    /**
  * Build federal tax options from array.
  *
  * @memberOf Step2Component
  */
    buildFedTaxOptions() {
        this.fedTaxOptions = this.validationService.fedTaxOptions;
    }

    /**
     * Build state tax options from range.
     *
     * @memberOf Step2Component
     */
    buildStateTaxOptions() {
        const min: number = this.validationService.stateTaxMin;
        const max: number = this.validationService.stateTaxMax;
        for (let i = min; i <= max; i++) {
            this.stateTaxOptions.push(i);
        }
    }

    /**
     * Toggle plan balance field.
     *
     * @param {string} currentHSAParticipant
     *
     * @memberOf Step2Component
     */
    togglePlanBalance(currentHSAParticipant: string) {
        // plan balance form control
        const control = this.formStep1.get('planBalance');

        // if user has a balance, show the input and set
        // form validators. If not, hide input and
        // remove validators.
        if (currentHSAParticipant === 'Y') {
            this.showPlanBalance = true;
            control.setValidators(this.planBalanceValidations);
            if (control.value === null || control.value === 0) {
                control.setValue('');
            }
        } else {
            this.showPlanBalance = false;
            control.setValidators(null);
        }

        // update control value and validity
        control.updateValueAndValidity();
    }



    /**
     * Rebuild retirement age options based on input current age value.
     * Calculate catchup eligibility based on age.
     *
     * @param {number} age
     *
     * @memberOf Step1Component
     */
    // coverageTypeChange(mytype: number) {
    //     const newAge = Math.max(Number(age) + 1, this.validationService.minRetAge);
    //     this.buildRetirementAgeOptions(newAge);
    //     this.co
    // }


    currentAgeChange(age: number) {
        const newAge = Math.max(Number(age) + 1, this.validationService.minRetAge);
        this.buildRetirementAgeOptions(newAge);
        this.setCatchupEligible(age);
    }



    /**
     * Determine catchup eligiblity based on age param.
     *
     * @param {number} age
     *
     * @memberOf Step1Component
     */
    setCatchupEligible(age: number) {
        this.calculationService.calcCatchupEligible(age);
        this.catchupEligible = this.calculationService.catchupEligible;
    }

    /**
     * Get CONSTANTS for use in HTML.
     *
     * @readonly
     *
     * @memberOf Step1Component
     */
    get constants() {
        return CONSTANTS;
    }
}
