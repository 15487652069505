import { Component, OnInit, OnDestroy } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { PageService } from '../pages.service';
import { PageData } from '../pages.model';
import { CalculationsService } from '../shared/calculations/calculations.service';
import { FormDataService } from '../shared/form-data/form-data.service';
import { FormData } from '../shared/form-data/form-data.model';
import { ProjResults } from '../shared/calculations/proj-results.type';
import { ValidationService } from '../shared/form-validations/validation.service';

// import { ChartModule } from 'angular2-highcharts';
import * as Highcharts from "highcharts";
import { CONSTANTS } from '../shared/calculations/calc-constants';
import { SummaryItem } from './summary-item/summary-item.type';
// import { ValueConverter } from '@angular/compiler/src/render3/view/template';

import { ReportDataService } from '../../shared/models/reportdata.service';
import { ReportData } from '../../shared/models/reportdata.model';

declare var Slider: any;

@Component({
    selector: 'app-summary',
    templateUrl: './summary.component.html',
    styleUrls: ['./summary.component.css']
})
export class SummaryComponent implements OnInit, OnDestroy {

    // page
    pageData: PageData;
    reportData: ReportData;

    // chart
    options: any;
    chart: any;
    Highcharts: typeof Highcharts = Highcharts; // required
    updateFlag: boolean;
    // form
    formSummary: FormGroup;
    formData: FormData;
    formDefaults: FormData;
    formErrors: any;
    validationMessages: any;

    // projections
    projResults: ProjResults;
    altResults: ProjResults;
    projRetBalance: number;
    altRetBalance: number;
    projSeriesVisible = true;
    altSeriesVisible = true;

    // sliders
    sliderHSAContribAlt: any;
    sliderHSASpendAlt: any;
    hsaMaxContrib: number;
    hsaMaxSpend: number;
    hsaMinContrib: number;
    hsaMinSpend: number;
    contribAtMax: boolean;
    spendAtMin: boolean;
    showAlternate: boolean;

    // variables
    singleCoverage: boolean;
    familyCoverage: boolean;
    retirementNeed: number;

    // summary values
    summaryItems: Array<SummaryItem>;
    assumptionItems: Array<SummaryItem>;

    hsaContrib: number;
    retirementAge: number;
    taxTotal: number;
    ror: number;
    hsaUsage: string;
    hsaOOP: number;
    taxSavings: number;

    formSubscription: any;

    currentAgeOptions: Array<number> = [];
    retireAgeOptions: Array<number> = [];

    fedTaxOptions: Array<number> = [];
    stateTaxOptions: Array<number> = [];

    /**
     * Creates an instance of SummaryComponent.
     * @param {PageService} pageService
     * @param {CalculationsService} calculationService
     * @param {FormBuilder} formBuilder
     * @param {FormDataService} formDataService
     * @param {ValidationService} validationService
     * @param {CurrencyPipe} currencyPipe
     *
     * @memberOf SummaryComponent
     */
    constructor(private pageService: PageService,
        private reportDataService: ReportDataService,
        private calculationService: CalculationsService,
        private formBuilder: FormBuilder,
        private formDataService: FormDataService,
        private validationService: ValidationService,
        private currencyPipe: CurrencyPipe) { }

    /**
     * Init actions when Angular is done creating the component.
     *
     * @memberOf SummaryComponent
     */
    ngOnInit() {
        this.initPageData();
        this.initFormData();
        this.calculationService.calcTaxSavings();
        this.initChartData();

        this.buildCurrentAgeOptions();
        this.buildRetirementAgeOptions(this.validationService.minRetAge);
        this.buildFedTaxOptions();
        this.buildStateTaxOptions();
        this.updateFlag = false;
        this.setVariables();
        this.initSliders();
        this.initYourSummary();
        //this.calc();
       
        this.recalculateChart();
    }

    /**
     * Destroy actions when Angular is done with component.
     *
     * @memberOf SummaryComponent
     */
    ngOnDestroy() {
        this.formSubscription.unsubscribe();
    }

    /**
     * Inits page specific data and sends this data to the page service.
     *
     * @memberOf SummaryComponent
     */
    initPageData() {
        this.pageData = {
            currentPage: 'summary',
            nextPage: '',
            prevPage: 'step3',
            stepNum: 0,
            progress: 0,
            firstPage: false,
            lastPage: true,
            showProgress: false,
            stepTitle: 'Summary and planning',
            addPrint: true,
            showFootnote: true,
        };
        this.pageService.initPage(this.pageData);
    }




    initReportData() {
        this.reportData = {
            rptIntro1: '',
            rptIntro2: '',
            summaryFootnote: '',
            client_name: '',
            advisor_name: '',
            advisor_company: '',
            advisor_phone: '',
            advisor_email: '',
            projRetBalance: '',
            altRetBalance: '',
            hsaContrib: '',
            hsaOOP: '',
            hsaSpendAlt: '',
            hsaContribAlt: '',
            taxSavings: '',
            retirementAge: '',
            fedTax: '',
            stateTax: '',
            ror: ''
        };
        this.reportDataService.initReport(this.reportData);
    }


    /**
     * Save Highcharts chart instance.
     *
     * @param {any} chartInstance
     *
     * @memberOf SummaryComponent
     */
    saveChartInstance(chartInstance) {
        this.chart = chartInstance;
    }
    ngAfterViewInit() {

        this.reportDataService.reportData.rptIntro1 = document.getElementById("rptIntro1").innerText;
        this.reportDataService.reportData.rptIntro2 = document.getElementById("rptIntro2").innerText;
        this.reportDataService.reportData.summaryFootnote = ""; // removed from site - document.getElementById("summaryFootnote").innerText;


        this.reportDataService.reportData.projRetBalance = this.projRetBalance.toString();
        this.reportDataService.reportData.altRetBalance = this.altRetBalance.toString();

        this.reportDataService.reportData.taxSavings = this.calculationService.taxSavings.toString();

        this.reportDataService.reportData.hsaOOP = this.formData.hsaOOP.toString();
        this.reportDataService.reportData.hsaContrib = this.formData.hsaContrib.toString();
        this.reportDataService.reportData.hsaSpendAlt = this.formData.hsaSpendAlt.toString();
        this.reportDataService.reportData.hsaContribAlt = this.formData.hsaContribAlt.toString();
        this.reportDataService.reportData.retirementAge = this.formData.retirementAge.toString();
        this.reportDataService.reportData.fedTax = this.formData.fedTax.toString();
        this.reportDataService.reportData.stateTax = this.formData.stateTax.toString();
        this.reportDataService.reportData.ror = this.formData.ror.toString();


    }

    initChartData() {
        
        
        // calculate projection results
        this.calculationService.calcProjResults();
        this.projResults = this.calculationService.projResults;
        this.projRetBalance = this.projResults.retirementBalance;

        // alternate projection results
        this.calculationService.calcAltProjResults();
        this.altResults = this.calculationService.altProjResults;
        this.altRetBalance = this.altResults.retirementBalance;

        this.setPDFData();

        // Highcharts chart options
        const that = this;
        this.options = {
            chart: {
                type: 'column',
                height: 400,
                // renderTo: 'chart-container',
                // backgroundColor: '#F5F5F5',
                spacingLeft: 20,
                spacingRight: 20,
                spacingTop: 20,
                style: {
                    fontFamily: 'inherit',
                }
            },
            title: {
                text: 'Your Estimated HSA Savings at Retirement',
                useHTML: true,
                style: {
                    color: '#363636',
                    align: 'center',
                    'background-color': '#fff',
                    'text-align': 'center',
                    'font-family': 'inherit',
                    'width': '100%',
                }
            },
            legend: {
                enabled: false,
            },
            yAxis: {
                title: { text: '' },
                min: 0,
                labels: {
                    formatter: function () {
                        return that.currencyPipe.transform(this.value, 'USD', 'symbol', '1.0-0');
                    }
                },
                reversedStacks: false,
                gridLineColor: '#fff'
            },
            tooltip: {
                pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b>',
                formatter: function () {
                    return '<b>' + this.series.name + '</b>: ' + that.currencyPipe.transform(this.point.y, 'USD', 'symbol', '1.0-0');
                }
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    // pointWidth: 16,
                },
                column: {
                    // stacking: 'normal',
                    grouping: false,
                },
                // column: {
                //     grouping: true,
                //     pointPadding: 0,
                //     groupPadding: .1,
                //     borderWidth: 0
                // }
            },
            credits: {
                enabled: false
            },
        };

        // add x-axis
        const xAxis = [];
        xAxis.push({
            labels: { enabled: true },
            title: { text: 'Years' },
            categories: this.projResults.year,
            lineColor: 'transparent',
            tickLength: 0
        });
        this.options['xAxis'] = xAxis;

        // add data series
        const series = [];
        series.push({
            name: 'Estimated savings at retirement age',
            data: this.projResults.value,
            //color: '#012169',
            color: '#1D95B2',
            index: 1,
            legendIndex: 0,
        });
        series.push({
            name: 'Potential balance with changes to savings/spending',
            data: this.altResults.value,
            color: '#D5D5D5',
            index: 0,
            legendIndex: 1,
        });
        this.updateFlag = true;
        this.options['series'] = series;
    }

    /**
     * Init chart data for Highcharts.
     *
     * @memberOf SummaryComponent
     */
    initChartDataNEW() {

        // calculate projection results
        this.calculationService.calcProjResults();
        this.projResults = this.calculationService.projResults;
        this.projRetBalance = this.projResults.retirementBalance;

        // alternate projection results
        this.calculationService.calcAltProjResults();
        this.altResults = this.calculationService.altProjResults;
        this.altRetBalance = this.altResults.retirementBalance;

        // Highcharts chart options
        const that = this;

        this.options = {
            chart: {
                type: 'column',
                // renderTo: 'chart-container',
                //backgroundColor: '#F5F5F5',
                spacingLeft: 20,
                spacingRight: 20,
                spacingTop: 20,
                style: {
                    fontFamily: 'inherit',
                }
            },
            title: {
                text: 'Your Estimated HSA Savings at Retirement',
                useHTML: true,
                style: {
                    color: '#363636',
                    align: 'center',
                    'background-color': '#fff',
                    'text-align': 'center',
                    'font-family': 'inherit',
                    'width': '100%',
                }
            },
            legend: {
                enabled: false,
            },
            yAxis: {
                title: { text: '' },
                min: 0,
                labels: {
                    formatter: function () {
                        return that.currencyPipe.transform(this.value, 'USD', 'symbol', '1.0-0');
                    }
                },
                reversedStacks: false,
                gridLineColor: '#fff'
            },
            tooltip: {
                pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b>',
                formatter: function () {
                    return '<b>' + this.series.name + '</b>: ' + that.currencyPipe.transform(this.point.y, 'USD', 'symbol', '1.0-0');
                }
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    },
                    pointWidth: 6,
                    groupPadding: .1
                },
                column: {
                    grouping: true,
                    pointPadding: 0,
                    groupPadding: .1,
                    borderWidth: 0
                }
            },
            credits: {
                enabled: false
            },
        };

        // add x-axis
        const xAxis = [];
        xAxis.push({
            labels: { enabled: true },
            title: { text: 'Years' },
            categories: this.projResults.year,
            lineColor: 'transparent',
            tickLength: 0,
        });
        this.options['xAxis'] = xAxis;

        // add data series
        const series = [];
        series.push({
            name: 'Estimated savings at retirement age',
            data: this.projResults.value,
            color: '#1D95B2',
            index: 1,
            legendIndex: 0, pointPadding: 0
        });
        series.push({
            name: 'Potential balance with changes to savings/spending',
            data: this.altResults.value,
            color: '#D5D5D5',
            index: 0,
            legendIndex: 1, pointPadding: 0
        });
        this.options['series'] = series;


        // this.options = {
        //     chart: {
        //         type: 'column',
        //         // renderTo: 'chart-container',
        //         backgroundColor: '#F5F5F5',
        //         spacingLeft: 20,
        //         spacingRight: 20,
        //         spacingTop: 20,
        //         style: {
        //             fontFamily: 'inherit',
        //         }
        //     },
        //     title: {
        //         text: 'Your Estimated HSA Savings at Retirement',
        //         useHTML: true,
        //         style: {
        //             color: '#363636',
        //             align: 'center',
        //             'background-color': '#fff',
        //             'text-align': 'center',
        //             'font-family': 'inherit',
        //             'width': '100%',
        //         }
        //     },
        //     legend: {
        //         enabled: false,
        //     },
        //     yAxis: {
        //         title: { text: '' },
        //         min: 0,
        //         labels: {
        //             formatter: function () {
        //                 return that.currencyPipe.transform(this.value, 'USD', 'symbol', '1.0-0');
        //             }
        //         },
        //         reversedStacks: false,
        //         gridLineColor: '#fff'
        //     },
        //     tooltip: {
        //         pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b>',
        //         formatter: function () {
        //             return '<b>' + this.series.name + '</b>: ' + that.currencyPipe.transform(this.point.y, 'USD', 'symbol', '1.0-0');
        //         }
        //     },
        //     plotOptions: {
        //         series: {
        //             marker: {
        //                 enabled: false
        //             },
        //             pointWidth: 16,
        //         },
        //         column: {
        //             // stacking: 'normal',
        //             grouping: false,
        //         }
        //     },
        //     credits: {
        //         enabled: false
        //     },
        // };

        // // add x-axis
        // const xAxis = [];
        // xAxis.push({
        //     labels: { enabled: false },
        //     title: { text: 'Years' },
        //     categories: this.projResults.year,
        //     lineColor: 'transparent',
        //     tickLength: 0
        // });
        // this.options['xAxis'] = xAxis;

        // // add data series
        // const series = [];
        // series.push({
        //     name: 'Estimated savings at retirement age',
        //     data: this.projResults.value,
        //     color: '#1D95B2',
        //     index: 1,
        //     legendIndex: 0,
        // });
        // series.push({
        //     name: 'Potential balance with changes to savings/spending',
        //     data: this.altResults.value,
        //     color: '#D5D5D5',
        //     index: 0,
        //     legendIndex: 1,
        // });
        // this.options['series'] = series;
    }

    /**
    * Build the options for the retirement age select.
    * Confined by a age param and maxRetAge class property.
    *
    * @param {number} age
    *
    * @memberOf Step1Component
    */
    buildRetirementAgeOptions(age: number) {
        // build retirement age options
        const min: number = age;
        const max: number = this.validationService.maxRetAge;
        this.retireAgeOptions = [];
        for (let i = min; i <= max; i++) {
            this.retireAgeOptions.push(i);
        }

        // update retirement age if selected current age is greater
        const minAge = this.formSummary.controls['retirementAge'].value;
        if (age > minAge) {
            this.formSummary.patchValue({ retirementAge: age });
        }
    }
    /**
       * Build the options for the current age select.
       * Confined by the minAge/maxAge class properties.
       *
       * @memberOf Step1Component
       */
    buildCurrentAgeOptions() {
        const min = this.validationService.minCurrentAge;
        const max = this.validationService.maxCurrentAge;
        for (let i = min; i <= max; i++) {
            this.currentAgeOptions.push(i);
        }
    }


    /**
    * Build federal tax options from array.
    *
    * @memberOf Step2Component
    */
    buildFedTaxOptions() {
        this.fedTaxOptions = this.validationService.fedTaxOptions;
    }

    /**
     * Build state tax options from range.
     *
     * @memberOf Step2Component
     */
    buildStateTaxOptions() {
        const min: number = this.validationService.stateTaxMin;
        const max: number = this.validationService.stateTaxMax;
        for (let i = min; i <= max; i++) {
            this.stateTaxOptions.push(i);
        }
    }

    /**
     * Set variables for use in HTML.
     *
     * @memberOf SummaryComponent
     */
    setVariables() {

        //console.log("setVariables");

        // current form data
        const formData = this.formData;

        // single vs family coverage and retirement needs
        if (formData.coverageType === 'S') {
            this.singleCoverage = true;
            this.familyCoverage = false;
        } else {
            this.singleCoverage = false;
            this.familyCoverage = true;
        }

        // retirement need
        this.retirementNeed = this.calculationService.retirementNeed;

        // current HSA contribution amount
        this.hsaContrib = formData.hsaContrib;

        // alternate HSA contrib range
        // between current contribution and IRS max
        this.hsaMaxContrib = Number(this.calculationService.hsaMax);
        this.hsaMinContrib = Number(formData.hsaContrib);

        // alternate HSA spend range
        // must be between $0 and current spend
        this.hsaMaxSpend = Number(formData.hsaSpend);
        this.hsaMinSpend = 0;

        // flags for contributing at max and spending at min
        // leads to determination of whether we show alternate scenario
        this.contribAtMax = this.calculationService.contribAtMax;
        this.spendAtMin = this.calculationService.spendAtMin;
        this.showAlternate = this.calculationService.flagShowAlternateSummary;

    }

    /**
     * Init data needed for form.
     *
     * @memberOf SummaryComponent
     */
    initFormData() {
        this.formDefaults = this.formDataService.getDefaults();
        this.formData = this.formDataService.getFormData();
        this.formErrors = this.validationService.validationErrors;
        this.buildForm();
    }

    /**
     * Use FormBuilder to create form.
     * Subscribe to form value changes and validate.
     * Save data to form data service if valid.
     * Calculate values as needed.
     *
     * @memberOf SummaryComponent
     */
    buildForm() {
        //console.log("buildForm");
        // build summary form
        this.formSummary = this.formBuilder.group({
            hsaContribAlt: [this.formData.hsaContribAlt, Validators.required],
            hsaSpendAlt: [this.formData.hsaSpendAlt, Validators.required],
            retirementAge: [this.formData.retirementAge, Validators.required],
            fedTax: [this.formData.fedTax, Validators.required],
            stateTax: [this.formData.stateTax, Validators.required],
            ror: [this.formData.ror, Validators.required],

        });

        // set current form data in formDataService
        this.formDataService.currentFormGroup = this.formSummary;

        // subscribe to summary form changes
        this.formSubscription = this.formSummary.valueChanges.subscribe(data => {
            this.formErrors = this.validationService.validateFormData(data, this.formSummary);
            if (this.formSummary.valid) {
                this.formDataService.setFormData(data);
            }
            this.calculationService.calcHSAMax(data.currentAge, data.coverageType);
            this.calculationService.calcDefaultHSAContribSpend('force');
            this.calculationService.calcRetirementNeed(data.coverageType);
            this.calculationService.calcYearsUntilRetirement(data.currentAge, data.retirementAge);
        });
    }

    setPDFData() {

      
        this.reportDataService.reportData.rptIntro1 = document.getElementById("rptIntro1").innerText;
        this.reportDataService.reportData.rptIntro2 = document.getElementById("rptIntro2").innerText;
        this.reportDataService.reportData.summaryFootnote = ""; // removed from site - document.getElementById("summaryFootnote").innerText;


        this.reportDataService.reportData.projRetBalance = this.projRetBalance.toString();
        this.reportDataService.reportData.altRetBalance = this.altRetBalance.toString();

        this.reportDataService.reportData.taxSavings = this.calculationService.taxSavings.toString();

        this.reportDataService.reportData.hsaOOP = this.formData.hsaOOP.toString();
        this.reportDataService.reportData.hsaContrib = this.formData.hsaContrib.toString();
        this.reportDataService.reportData.hsaSpendAlt = this.formData.hsaSpendAlt.toString();
        this.reportDataService.reportData.hsaContribAlt = this.formData.hsaContribAlt.toString();
        this.reportDataService.reportData.retirementAge = this.formData.retirementAge.toString();
        this.reportDataService.reportData.fedTax = this.formData.fedTax.toString();
        this.reportDataService.reportData.stateTax = this.formData.stateTax.toString();
        this.reportDataService.reportData.ror = this.formData.ror.toString();


    }

    setStateTax(e) {
      
        //  this.formDataService.setValue('stateTax', e.rate);
        //  this.formSummary.patchValue({ stateTax: e });
        //  this.calculationService.calcTaxSavings();
        //  this.setPDFData();
     }
     setFedTax(e) {
       
        //  this.formDataService.setValue('fedTax', e);
        //  this.formSummary.patchValue({ fedTax: e });
        //  this.calculationService.calcTaxSavings();
        //  this.setPDFData();
     }



    /**
     * Init alternate HSA contrib and spend sliders.
     *
     * @memberOf SummaryComponent
     */
    initSliders() {
        const that = this;

        // alternate HSA contribution slider
        this.sliderHSAContribAlt = new Slider('#hsaContribAlt', {
            min: this.hsaMinContrib,
            max: this.hsaMaxContrib,
            step: CONSTANTS.SLIDER_STEP,
            handle: 'square',
            value: this.formDataService.getValue('hsaContribAlt'),
            tooltip: 'always',
            formatter: function (value) {
                return that.currencyPipe.transform(value, 'USD', 'symbol', '1.0-0');
            }
        });

        // alternate HSA spend slider
        this.sliderHSASpendAlt = new Slider('#hsaSpendAlt', {
            min: this.hsaMinSpend,
            max: this.hsaMaxSpend,
            step: CONSTANTS.SLIDER_STEP,
            handle: 'square',
            value: this.formDataService.getValue('hsaSpendAlt'),
            tooltip: 'always',
            formatter: function (value) {
                return that.currencyPipe.transform(value, 'USD', 'symbol', '1.0-0');
            }
        });

        // alternate HSA contrib slider changes
        // set values manually (not picked up by valueChanges subscription)
        // recalculate alternate scenario
        this.sliderHSAContribAlt.on('change', function (e) {
            // contribution value from slider change event
            const contribVal = e.newValue;

            // set value of contrib slider
            that.sliderHSAContribAlt.setValue(contribVal, true, true);
            that.formDataService.setValue('hsaContribAlt', contribVal);

            // calculate
            that.recalculateChart();

            // set alternate spender saver flag
            that.calculationService.setAltSpenderSaverFlag(contribVal, that.sliderHSASpendAlt.getValue());

            // set flag to show alternate on results page
            that.calculationService.setShowAlternateResultsFlag();

            // calculate tax savings for alt contribs
            that.calculationService.calcTaxSavingsAlt();
        });

        // alternate HSA spend slider changes
        // set values manually (not picked up by valueChanges subscription)
        // recalculate alternate scenario
        this.sliderHSASpendAlt.on('change', function (e) {
            // spend value from slider change event
            const spendVal = e.newValue;

            // set value of spend slider
            that.sliderHSASpendAlt.setValue(spendVal, true, true);
            that.formDataService.setValue('hsaSpendAlt', spendVal);

            // calculate
            that.recalculateChart();

            // set alternate spender saver flag
            that.calculationService.setAltSpenderSaverFlag(that.sliderHSAContribAlt.getValue(), spendVal);

            // set flag to show alternate on results page
            that.calculationService.setShowAlternateResultsFlag();
        });
       
    }

    calc() {

        //// calculate
        this.recalculateChart();


        // set flag to show alternate on results page
        this.calculationService.setShowAlternateResultsFlag();

        // calculate tax savings for alt contribs
        this.calculationService.calcTaxSavingsAlt();
    }
    /**
     * Calculate alternate results.
     * Based on alternate contrib/spend inputs.
     *
     * @memberOf SummaryComponent
     */
    recalculateChart() {

        const altProjResults = this.calculationService.calcAltProjResults();
        const updatedAltResults = this.calculationService.altProjResults;

        // redraw chart
       // this.chart.series[0].setData(updatedAltResults.value);
        this.altRetBalance = updatedAltResults.retirementBalance;

        this.initChartData();
    }

    /**
     * Toggle Highcharts chart series.
     * Show/Hide series in chart on legend click event.
     *
     * @param {string} series
     *
     * @memberOf SummaryComponent
     */
    toggleSeries(series: string) {
        switch (series) {
            case 'proj':
                const projSeries = this.chart.series[1];
                if (this.projSeriesVisible) {
                    projSeries.hide();
                    this.projSeriesVisible = false;
                } else {
                    projSeries.show();
                    this.projSeriesVisible = true;
                }
                break;
            case 'alt':
                const altSeries = this.chart.series[0];
                if (this.altSeriesVisible) {
                    altSeries.hide();
                    this.altSeriesVisible = false;
                } else {
                    altSeries.show();
                    this.altSeriesVisible = true;
                }
                break;
        }
    }

    /**
     * Summary item content/values/actions.
     *
     * @memberOf SummaryComponent
     */
    initYourSummary() {
        const formData = this.formData;

        this.summaryItems = [
            {
                // title: 'You plan to contribute the following amount to your HSA annually:',
                title: 'Annual HSA contribution',
                value: formData.hsaContrib,
                link: '',
                modifier: 'currency',
            },
            {
                // title: 'Out-of-pocket costs for qualified medical expenses:',
                title: 'Qualified out-of-pocket medical expenses:',
                value: formData.hsaOOP,
                link: '',
                modifier: 'currency',
            },
            {
                title: 'Annual tax savings:',
                value: this.calculationService.taxSavings,
                link: '',
                modifier: 'currency',
            }
        ];

        this.assumptionItems = [
            {
                title: 'Retirement age:',
                value: formData.retirementAge,
                link: 'step1',
                modifier: '',
            },
            {
                title: 'Current tax rate:',
                value: Number(formData.stateTax) + Number(formData.fedTax),
                link: 'step2',
                modifier: 'percent',
            },
            {
                title: 'Investment rate of return:',
                value: formData.ror,
                link: 'step2',
                modifier: 'percent',
            }
        ];
    }

    /**
     * Calculate future value payment based on user inputs.
     *
     * @param {number} contrib
     * @param {number} spend
     * @returns
     *
     * @memberOf SummaryComponent
     */
    calcPayment(contrib: number, spend: number) {
        return contrib - spend;
    }
}
