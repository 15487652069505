import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';

import { PageService } from '../../pages.service';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { PageData } from '../../pages.model';

import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

    environmentName: string = environment.environmentName;

    totalSteps = 4;

    step: number;
    progress: number;
    showProgress: boolean;
    firstPage: boolean;
    stepTitle: string;

    private pageSubscription: any;

    /**
     * Creates an instance of HeaderComponent.
     * @param {PageService} pageService
     *
     * @memberOf HeaderComponent
     */
    constructor(private pageService: PageService,
        private cdr: ChangeDetectorRef) {}

    /**
     * Init actions when Angular is done creating the component.
     *
     * @memberOf HeaderComponent
     */
    ngOnInit() {
        this.pageSubscription = this.pageService.pageChange.subscribe((data) => {
            this.showProgress = data.showProgress;
            this.progress = data.progress;
            this.step = data.stepNum;
            this.stepTitle = data.stepTitle;
            this.firstPage = data.firstPage;
            this.cdr.detectChanges();
        });
    }

    /**
     * Destroy actions when Angular is done with component.
     *
     * @memberOf HeaderComponent
     */
    ngOnDestroy() {
        this.pageSubscription.unsubscribe();
    }

}
