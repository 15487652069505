import { Injectable } from '@angular/core';

import { FormDataService } from '../form-data/form-data.service';
import { FormData } from '../form-data/form-data.model';
import { ProjResults } from './proj-results.type';

import { CONSTANTS } from './calc-constants';
import { OOP_COSTS } from '../oop/oop-costs';
import { OOPProfile } from '../oop/oop-profile.type';
import { ValidationService } from '../form-validations/validation.service';

@Injectable()
export class CalculationsService {

    // user inputs
    formData: FormData;
    formDefaults: FormData;

    // calculated values
    hsaMax: number;
    defaultHSAContrib: number;
    defaultHSASpend: number;
    catchupEligible: boolean;
    OOPProfile: OOPProfile;
    hsaOOPCost: number;
    contribAtMax: boolean;
    spendAtMin: boolean;
    flagShowAlternateSummary: boolean;
    flagShowAlternateResults: boolean;
    taxSavings: number;
    taxSavingsTotal: number;
    taxSavingsAlt: number;
    taxSavingsTotalAlt: number;
    yearsUntilRetirement: number;
    totalTax: number;
    flagSpenderSaver: string;
    flagSpenderSaverAlt: string;
    retirementNeed: number;

    // projected values
    projResults: ProjResults;
    altProjResults: ProjResults;
    projRetBalance: number;
    altRetBalance: number;

    /**
     * Creates an instance of CalculationsService.
     * @param {FormDataService} formDataService
     * @param {ValidationService} validationService
     *
     * @memberOf CalculationsService
     */
    constructor(private formDataService: FormDataService,
        private validationService: ValidationService) {
        this.formData = this.formDataService.getFormData();
        this.formDefaults = new FormData();
        this.initCalc(this.formData);
    }

    /**
     * Init calc service using form values.
     *
     * @memberOf CalculationsService
     */
    initCalc(data: FormData) {

        // set OOP profile (L/M/H message IDs)
        this.setOOPProfile(data.currentAge, data.coverageType);

        // set OOP value
        this.setHSAOOP(data.hsaUsage);

        // set catchup eligibility flag
        this.calcCatchupEligible(data.currentAge);

        // calculate maximimum HSA contribution - IRS limit
        this.calcHSAMax(data.currentAge, data.coverageType);

        // calculate default value for HSA contrib/spend sliders
        this.calcDefaultHSAContribSpend();

        // calculate retirement need
        this.calcRetirementNeed(data.coverageType);

        // calculate years until retirement
        this.calcYearsUntilRetirement(data.currentAge, data.retirementAge);

        // calculate alterate contrib/spend values
        this.calcAlternateContrib(data.hsaContrib);
        this.calcAlternateSpend(data.hsaSpend);

        // set flag for max contributors / minimum spenders
        this.setMaxContributor(data.hsaContrib);
        this.setMinSpender(data.hsaSpend);
        this.setShowAlternateSummaryFlag();
        this.setShowAlternateResultsFlag();

        // set flag for spender/saver for default and alternate scenarios
        this.setSpenderSaverFlag(data.hsaContrib, data.hsaSpend);
        this.setAltSpenderSaverFlag(data.hsaContribAlt, data.hsaSpendAlt);

        // calculate tax savings
        this.calcTaxSavings();
        this.calcTaxSavingsAlt();

        // calculate projections for default/alternate scenarios
        this.calcProjResults();
        this.calcAltProjResults();
    }

    /**
     * Calculate maximum HSA contribution (IRS limit).
     * Based on age and coverage.
     *
     * @param {number} age
     * @param {string} coverage
     *
     * @memberOf CalculationsService
     */
    calcHSAMax(age: number, coverage: string) {
        switch (coverage) {
            case 'S':
                this.hsaMax = CONSTANTS.HSA_SINGLE;
                break;
            case 'F':
                this.hsaMax = CONSTANTS.HSA_FAMILY;
                break;
            default:
                this.hsaMax = CONSTANTS.HSA_SINGLE;
                break;
        }
        this.hsaMax += this.calcHSACatchup(age);
        this.validationService.hsaMaxContrib = this.hsaMax;
    }

    /**
     * Calculate HSA catchup.
     * Based on user age.
     *
     * @param {number} age
     * @returns
     *
     * @memberOf CalculationsService
     */
    calcHSACatchup(age: number) {
        if (age >= CONSTANTS.HSA_CATCHUP_AGE) {
            return CONSTANTS.HSA_CATCHUP;
        }
        return 0;
    }

    /**
     * Set flag for catchup eligible.
     *
     * @param {number} age
     *
     * @memberOf CalculationsService
     */
    calcCatchupEligible(age: number) {
        if (age >= CONSTANTS.HSA_CATCHUP_AGE) {
            this.catchupEligible = true;
        } else {
            this.catchupEligible = false;
        }
    }

    /**
     * Calculate retirement need for this user.
     * Based on coverage (single vs family).
     *
     * @param {string} coverage
     *
     * @memberOf CalculationsService
     */
    calcRetirementNeed(coverage: string) {
        if (coverage === 'S') {
            this.retirementNeed = CONSTANTS.RETIREMENT_NEED_SINGLE_F;
        } else {
            this.retirementNeed = CONSTANTS.RETIREMENT_NEED;
        }
    }

    calcYearsUntilRetirement(currentAge: number, retirementAge: number) {
        this.yearsUntilRetirement = retirementAge - currentAge + 1;
    }

    /**
     * Set profile for current user.
     * Based on age and coverage.
     * Assign message ID for this user's scenario.
     *
     * @memberOf CalculationsService
     */
    setOOPProfile(age: number, coverage: string) {
        if (age < 35) {
            switch (coverage) {
                case 'S':
                    this.OOPProfile = {
                        L: 'A100100',
                        M: 'A100200',
                        H: 'A100300',
                    };
                    break;
                case 'F':
                    this.OOPProfile = {
                        L: 'B100100',
                        M: 'B100200',
                        H: 'B100300',
                    };
                    break;
            }
        } else if (age < 50) {
            switch (coverage) {
                case 'S':
                    this.OOPProfile = {
                        L: 'C100100',
                        M: 'C100200',
                        H: 'C100300',
                    };
                    break;
                case 'F':
                    this.OOPProfile = {
                        L: 'D100100',
                        M: 'D100200',
                        H: 'D100300',
                    };
                    break;
            }
        } else {
            switch (coverage) {
                case 'S':
                    this.OOPProfile = {
                        L: 'E100100',
                        M: 'E100200',
                        H: 'E100300',
                    };
                    break;
                case 'F':
                    this.OOPProfile = {
                        L: 'F100100',
                        M: 'F100200',
                        H: 'F100300',
                    };
                    break;
            }
        }
    }

    /**
     * Set HSA OOP value for L/M/H/C scenarios.
     *
     * @param {string} usage
     *
     * @memberOf CalculationsService
     */
    setHSAOOP(usage: string) {
        if (usage !== 'C') {
            this.hsaOOPCost = OOP_COSTS[this.OOPProfile[usage]];
        } else {
            this.hsaOOPCost = this.formDataService.getValue('hsaOOP');
        }
        this.formDataService.setValue('hsaOOP', this.hsaOOPCost);
    }

    /**
     * Calculate projection for current scenario.
     *
     * @memberOf CalculationsService
     */
    calcProjResults() {
        // projection assumptions
        const assumptions = this.getProjectionAssumptions();

        // payment
        const pmt = this.calcPayment(this.formDataService.getValue('hsaContrib'), this.formDataService.getValue('hsaSpend'));

        // calculate projection results
        this.projResults = this.calcFV(assumptions.rate, assumptions.nper, pmt, assumptions.pv, assumptions.type);
        this.projRetBalance = this.projResults.retirementBalance;
    }

    /**
     * Calculate projection for alternate scenario.
     *
     * @memberOf CalculationsService
     */
    calcAltProjResults() {
        // projection assumptions
        const assumptions = this.getProjectionAssumptions();

        // payment
        const pmt = this.calcPayment(this.formDataService.getValue('hsaContribAlt'), this.formDataService.getValue('hsaSpendAlt'));

        // calculate alternate projection results
        this.altProjResults = this.calcFV(assumptions.rate, assumptions.nper, pmt, assumptions.pv, assumptions.type);
        this.altRetBalance = this.altProjResults.retirementBalance;
    }

    /**
     * Get projection assumptions.
     *
     * @returns {*}
     *
     * @memberOf CalculationsService
     */
    getProjectionAssumptions() {
        this.calcYearsUntilRetirement(this.formDataService.getValue('currentAge'), this.formDataService.getValue('retirementAge'));
        const projAssumptions = {
            rate: this.formDataService.getValue('ror'),
            nper: this.yearsUntilRetirement,
            pv: (this.formDataService.getValue('currentHSAParticipant') === 'Y' ? this.formDataService.getValue('planBalance') : 0),
            type: 0,
        };
        return projAssumptions;
    }

    /**
     * Calculate payment based on user inputs for use in projections.
     *
     * @param {number} contrib
     * @param {number} spend
     * @returns {number}
     *
     * @memberOf SummaryComponent
     */
    calcPayment(contrib: number, spend: number) {
        return contrib - spend;
    }

    /**
     * Calculate future value.
     *
     * @param {number} rate
     * @param {number} nper
     * @param {number} pmt
     * @param {number} pv
     * @param {number} type
     * @returns {Array} projResults
     *
     * @memberOf CalculationsService
     */
    calcFV(rate: number, nper: number, pmt: number, pv: number, type: number) {
        // create ROR variable for use in calc loop
        const ror = 1 + (rate / 100);

        // set annual value
        let annualValue = pv;

        // init proj results interface
        const projResults: ProjResults = {
            year: [],
            age: [],
            value: [],
            retirementBalance: 0,
        };

        // for each period...
        for (let i = 0; i < nper; i++) {
            // apply interest at beginning or end
            if (type === 0) {
                annualValue = (annualValue * ror) + pmt;
            } else {
                annualValue = (annualValue + pmt) * ror;
            }

            // add annual value to proj results
            projResults.value.push(annualValue);
            // add year to proj results
            projResults.year.push(CONSTANTS.currentYear + i);
            // add current age to proj results
            projResults.age.push(this.formDataService.getValue('currentAge') + i);
        }

        // set final balance in proj results
        projResults.retirementBalance = annualValue;

        return projResults;
    }

    /**
     * Calculate the alternate contribution amount.
     * Based on user HSA contribution and default increase assumption.
     *
     * @param {number} contribVal
     *
     * @memberOf CalculationsService
     */
    calcAlternateContrib(contribVal: number, update?: string) {
        if (contribVal === 0) {
            this.formDataService.setValue('hsaContribAlt', CONSTANTS.ALTERNATE_DOLLAR);
        } else {
            // calculate alternate contrib
            const hsaContribAlt = contribVal * (1 + (CONSTANTS.ALTERNATE_CONTRIB_PCT / 100));
            const roundedVal = CONSTANTS.SLIDER_STEP * Math.ceil(hsaContribAlt / CONSTANTS.SLIDER_STEP);

            // get existing alternate and base contrib values
            // if the alt is out of range then update it (or if we send the force flag)
            const currentHSAContribAlt = this.formDataService.getValue('hsaContribAlt');
            const currentHSAContrib = this.formDataService.getValue('hsaContrib');
            if (currentHSAContribAlt < currentHSAContrib
                || currentHSAContribAlt > this.hsaMax
                || update === 'force'
                || update === 'forcecontrib'
            ) {
                this.formDataService.setValue('hsaContribAlt', Math.min(roundedVal, this.hsaMax));
            }
        }
    }

    /**
     * Calculate alternate HSA spend amount.
     * Based on user HSA spend amount and default decrease assumption.
     *
     * @param {number} spendVal
     *
     * @memberOf CalculationsService
     */
    calcAlternateSpend(spendVal: number, update?: string) {
        // calculate alternate HSA spend
        const hsaSpendAlt = spendVal * (1 - (CONSTANTS.ALTERNATE_SPEND_PCT / 100));
        const roundedVal = CONSTANTS.SLIDER_STEP * Math.floor(hsaSpendAlt / CONSTANTS.SLIDER_STEP);

        // get existing alt and base HSA spend amounts
        // if we are not in range update (or if we send the force flag)
        const currentHSASpendAlt = this.formDataService.getValue('hsaSpendAlt');
        const currentHSASpend = this.formDataService.getValue('hsaSpend');
        if (currentHSASpendAlt > currentHSASpend
            || currentHSASpendAlt === 0
            || update === 'force'
            || update === 'forcespend'
        ) {
            this.formDataService.setValue('hsaSpendAlt', Math.max(roundedVal, 0));
        }
    }

    /**
     * Set flag if user is contributing at the HSA maximum.
     *
     * @param {number} contribVal
     *
     * @memberOf CalculationsService
     */
    setMaxContributor(contribVal: number) {
        if (contribVal === this.hsaMax) {
            this.contribAtMax = true;
        } else {
            this.contribAtMax = false;
        }
    }

    /**
     * Set flag if user is spending at the minimum ($0).
     *
     * @param {number} spendVal
     *
     * @memberOf CalculationsService
     */
    setMinSpender(spendVal: number) {
        if (spendVal === 0) {
            this.spendAtMin = true;
        } else {
            this.spendAtMin = false;
        }
    }

    /**
     * Flag to determine if we should show the alternate scenario.
     *
     * @memberOf CalculationsService
     */
    setShowAlternateSummaryFlag() {
        if (this.contribAtMax && this.spendAtMin) {
            this.flagShowAlternateSummary = false;
        } else {
            this.flagShowAlternateSummary = true;
        }
    }

    setShowAlternateResultsFlag() {
        const contrib = this.formDataService.getValue('hsaContrib');
        const spend = this.formDataService.getValue('hsaSpend');
        const contribAlt = this.formDataService.getValue('hsaContribAlt');
        const spendAlt = this.formDataService.getValue('hsaSpendAlt');
        if (contrib === contribAlt && spend === spendAlt) {
            this.flagShowAlternateResults = false;
        } else {
            this.flagShowAlternateResults = true;
        }
    }

    /**
     * Set spender saver flag for base scenario.
     *
     * @param {number} contrib
     * @param {number} spend
     *
     * @memberOf CalculationsService
     */
    setSpenderSaverFlag(contrib: number, spend: number) {
        this.flagSpenderSaver = this.calcSpenderSaver(contrib, spend);
    }

    /**
     * Set spender saver flag for alternate scenario.
     *
     * @param {number} contrib
     * @param {number} spend
     *
     * @memberOf CalculationsService
     */
    setAltSpenderSaverFlag(contrib: number, spend: number) {
        this.flagSpenderSaverAlt = this.calcSpenderSaver(contrib, spend);
    }

    /**
     * Set spender vs saver flag.
     *
     * @param {number} contrib
     * @param {number} spend
     * @returns
     *
     * @memberOf CalculationsService
     */
    calcSpenderSaver(contrib: number, spend: number) {
        const percent = (spend / contrib) * 100;
        if (percent >= CONSTANTS.SPEND_SAVE_PCT || isNaN(percent)) {
            return 'spender';
        } else {
            return 'saver';
        }
    }

    /**
     * Calculate HSA tax savings.
     * Based on form fed/state tax inputs and HSA contribution.
     *
     * @memberOf CalculationsService
     */
    calcTaxSavings() {
        this.calcTotalTax(Number(this.formDataService.getValue('fedTax')), Number(this.formDataService.getValue('stateTax')));
        this.taxSavings = Number(this.formDataService.getValue('hsaContrib')) * (this.totalTax / 100);
        this.taxSavingsTotal = this.taxSavings * this.yearsUntilRetirement;
    }

    /**
     * Calculate HSA tax savings for alternate scenario.
     *
     * @memberOf CalculationsService
     */
    calcTaxSavingsAlt() {
        this.calcTotalTax(Number(this.formDataService.getValue('fedTax')), Number(this.formDataService.getValue('stateTax')));
        this.taxSavingsAlt = Number(this.formDataService.getValue('hsaContribAlt')) * (this.totalTax / 100);
        this.taxSavingsTotalAlt = this.taxSavingsAlt * this.yearsUntilRetirement;
    }

    /**
     * Calculate total tax based on federal and state values.
     *
     * @param {number} fed
     * @param {number} state
     *
     * @memberOf CalculationsService
     */
    calcTotalTax(fed: number, state: number) {
        this.totalTax = fed + state;
    }

    /**
     * Calculate the default value we show on the HSA contrib/spend sliders.
     * Currently want this to be the halfway point.
     *
     * @memberOf CalculationsService
     */
    calcDefaultHSAContribSpend(update?: string) {

        // calculate default HSA contribution - round up to nearest slider step interval
        const currentContrib = Number(this.formDataService.getValue('hsaContrib'));
        const defaultContrib = this.hsaMax / 2;
        const roundedContrib = CONSTANTS.SLIDER_STEP * Math.ceil(defaultContrib / CONSTANTS.SLIDER_STEP);
        const conbribUse = (currentContrib === 0 ? defaultContrib : currentContrib);

        // calculate default HSA spend - round down to nearest slider step interval
        const hsaOOP = Number(this.formDataService.getValue('hsaOOP'));
        const defaultSpend = Math.min(hsaOOP, conbribUse) / 2;
        const roundedSpend = CONSTANTS.SLIDER_STEP * Math.floor(defaultSpend / CONSTANTS.SLIDER_STEP);

        // set values in class properties
        this.defaultHSAContrib = roundedContrib;
        this.defaultHSASpend = roundedSpend;

        // if form has default values in contrib/spend, assume it is first time here and update these default values to something useful
        // if there is a value in either contrib/spend, we assume user has changed something so do not reset
        // NOTE: if user intentionally models defaults ($0/$0), this will update values on a page refresh.
        // The tool is not intended for these users anyways, so this should be ok.
        // Also we will set these form values if we want to "force" the update
        // This functionality is needed for when someone changes from single/family or age and limits change
        if (this.formDataService.getValue('hsaContrib') === this.formDefaults.hsaContrib
            && this.formDataService.getValue('hsaSpend') === this.formDefaults.hsaSpend
            || update === 'force'
        ) {
            this.formDataService.setValue('hsaContrib', roundedContrib);
            this.formDataService.setValue('hsaSpend', roundedSpend);
            this.calcAlternateContrib(roundedContrib, update);
            this.calcAlternateSpend(roundedSpend, update);
        }

        // or we can force contrib / spend on their own
        if (update === 'forcespend') {
            this.formDataService.setValue('hsaSpend', roundedSpend);
            this.calcAlternateSpend(roundedSpend, update);
        }

        if (update === 'forcecontrib') {
            this.formDataService.setValue('hsaContrib', roundedContrib);
            this.calcAlternateContrib(roundedContrib, update);
        }

    }
}
