import { NgModule } from '@angular/core';
import { Routes, Router, RouterModule } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { SessionComponent } from './shared/session/session.component';

const appRoutes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'session', component: SessionComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }
