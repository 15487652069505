import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CollapseModule } from 'ngx-bootstrap/collapse';
// import { CustomFormsModule } from 'ng2-validation';



import { HighchartsChartModule } from 'highcharts-angular';

import { HeaderComponent } from './templates/header/header.component';
import { FooterComponent } from './templates/footer/footer.component';
import { HomeComponent } from './home/home.component';
import { Step1Component } from './step1/step1.component';
import { Step2Component } from './step2/step2.component';
import { Step3Component } from './step3/step3.component';
import { Step4Component } from './step4/step4.component';
import { SummaryComponent } from './summary/summary.component';
import { ResultsComponent } from './results/results.component';
import { NavigationComponent } from './shared/navigation/navigation.component';
import { PagesRoutingModule } from './pages.routing';
import { PageData } from './pages.model';
import { PagesComponent } from './pages.component';
import { ErrorMessageComponent } from './shared/error-message/error-message.component';
import { SessionComponent } from '../shared/session/session.component';

import { SessionService } from '../shared/session/session.service';
import { PageService } from './pages.service';
import { FormDataService } from './shared/form-data/form-data.service';
import { CalculationsService } from './shared/calculations/calculations.service';
import { ValidationService } from './shared/form-validations/validation.service';
import { SummaryItemComponent } from './summary/summary-item/summary-item.component';
import { ActionItemComponent } from './results/action-item/action-item.component';
import { AnalyticsService } from '../shared/analytics/analytics.service';
import { AccordionModule } from 'ngx-bootstrap/accordion';


declare var require: any;

export function highchartsFactory() {
  return require('highcharts');
}


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ProgressbarModule.forRoot(),
        ModalModule.forRoot(),
        TooltipModule.forRoot(),
        CollapseModule.forRoot(),
        PagesRoutingModule,
        // CustomFormsModule,
        HighchartsChartModule,
        AccordionModule.forRoot(),
    ],
    exports: [
        HeaderComponent,
        FooterComponent,
        PagesComponent,
    ],
    declarations: [
        HeaderComponent,
        FooterComponent,
        HomeComponent,
        Step1Component,
        Step2Component,
        Step3Component,
        Step4Component,
        SummaryComponent,
        ResultsComponent,
        NavigationComponent,
        PagesComponent,
        ErrorMessageComponent,
        SummaryItemComponent,
        ActionItemComponent,
        SessionComponent,
    ],
    providers: [
        PageService,
        PageData,
        SessionService,
        AnalyticsService,
        FormDataService,
        CalculationsService,
        ValidationService,
        CurrencyPipe
    ]
})
export class PagesModule { }
